import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { LatLng } from 'leaflet';
import { CityPolygonModel } from 'src/app/models/Maps/CityPolygonModel';

@Component({
  selector: 'app-change-location',
  templateUrl: './change-location.page.html',
  styleUrls: ['./change-location.page.scss'],
})
export class ChangeLocationPage implements OnInit {

   //LOCATION
   mapActivated = true;
   city: CityPolygonModel;
 
   address: string;
   map: any;
   coords: LatLng;
   locationMarker: any;
   locationMarkerCircle: any;
   isMarkerInCity: boolean;
 
   searchStreetTimeout: any;
   searchStreetDisabled: boolean;
   //

   
  constructor(
    private nav: NavController,
    public ref: ChangeDetectorRef,
    private modal: ModalController
  ) { }

  ngOnInit() {
  }

  
  back() {
    this.modal.dismiss();
  }

  coordsChange(event) {
    this.coords = event;
    console.log(event);
  }
  addressChange(event) {
    this.address = event;
  }
  markerInCityChange(event) {
    console.log(event);
    this.isMarkerInCity = event;
    this.ref.detectChanges();
  }

  onSubmit() {
    this.modal.dismiss(this.coords);
  }

}
