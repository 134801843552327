import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapComponent } from './map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@NgModule({
  declarations: [
    MapComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    LeafletModule
],
  exports: [
    MapComponent
  ]
})
export class MapComponentModule {}