import { NgModule } from '@angular/core';
import { ImageUrlPipe } from './image-url.pipe';
import { ImageModePipe } from './image-mode.pipe';
import { LocalizedDatePipe } from './LocalizedDatePipe/localized-date-pipe.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TruncatePipe } from './truncate.pipe';
import { PhoneFormatPipe } from './PhoneFormatPipe/phone-format.pipe';
import { RelativeTimePipe } from './RelativeTimePipe/relative-time.pipe';
import { RelativeTimeClock } from './RelativeTimePipe/relative-time.clock';
import { DomainPipe } from './domain.pipe';
import { DatestringPipe } from './datestring.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    ImageUrlPipe, 
    ImageModePipe, 
    LocalizedDatePipe,
    SafeUrlPipe,
    TruncatePipe,
    PhoneFormatPipe,
    RelativeTimePipe,
    DomainPipe,
    DatestringPipe
  ],
  exports: [
    ImageUrlPipe,
    ImageModePipe,
    LocalizedDatePipe,
    SafeUrlPipe,
    TruncatePipe,
    PhoneFormatPipe,
    RelativeTimePipe,
    DomainPipe,
    DatestringPipe
  ],
  providers: [
    RelativeTimeClock
  ]
})
export class ApplicationPipesModule {}