<div class="card mode-{{viewMode | lowercase}}" [style.--color]="event.color">
  <div class="color-pill"></div>
  <div *ngIf="event.image && viewMode != 'AGENDA'" class="img-wrapper">
    <img *ngIf="event.image" [src]="event.image | imageUrl" [alt]="event.name">
    <div class="img-overlay">
      <div class="daynumber">{{showDate.date()}}</div>
      <div class="month-name">{{showDate.format('MMMM')}}</div>
      <div class="hour" *ngIf="!event.allDay && (!event.dateTo || sameDayHours)">{{event.dateFrom_m.format('LT')}}</div>
    </div>
  </div>
  <div class="info-wrapper">
    
    <div class="text-block">
      <div class="name">{{event.name}}</div>
      
      <div class="date-wrapper">
        <div class="date">
          <i class="fas fa-calendar-day fa-fw"></i> {{event.dateFrom_m.format('L')}}<ng-container *ngIf="event.dateTo && !sameDayHours"><span class="mx-1">-</span>{{event.dateTo | date:'shortDate'}}</ng-container> <!-- In one line to avoid unwanted spaces -->
        </div>
        <div class="hour" *ngIf="!event.allDay && (!event.dateTo || sameDayHours)">
          <i class="fas fa-clock fa-fw"></i> {{event.dateFrom_m.format('LT')}} h<ng-container *ngIf="event.dateTo"><span class="mx-1">-</span>{{event.dateTo_m.format('LT')}} h</ng-container> <!-- In one line to avoid unwanted spaces -->
        </div>
      </div>
      
      <div class="location" *ngIf="event.locationString">
        <i class="fas fa-map-marker-alt fa-fw"></i> {{event.locationString}}
      </div>
      
      <!--
        <table class="stats">
        <tr>
          <th>Assistiran</th><th>Seguidors</th>
        </tr>
        <tr>
          <td>{{event.id*2}}</td><td>{{event.id*5}}</td>
        </tr>
      </table>
      -->

    </div>

    <div class="buttons-wrapper">
      <ion-button size="small" shape="round">+ Info</ion-button>
    </div>
  </div>
</div>