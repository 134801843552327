import { ApiService } from './api.service';
import { STORAGE_TOWNS_REQUESTED } from './../../constants';
import { TownSearchDTO } from './../models/TownSearchDTO';
import { TownModel } from './../models/TownModel';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { STORAGE_TOWN } from 'src/constants';
import { environment } from 'src/environments/environment';
import { CommunityService } from './community/community.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage,
    private api: ApiService,
    private communityService: CommunityService
    ) {

      //Init storage town
      this.GetTown().then((res: TownModel) => {
        if (res && (!res.env || res.env == environment.tag)) {
          this.communityService.Set(res);

          if(this.SyncNeeded(res.lastSync)){
            this.api.Town_GetById(res.id)
            .then(newRes => {
              this.SetTown(newRes);
            }).catch(() => {
              this.communityService.Set(res);
            });
          }

        } else {
          this.communityService.Set(null);
        }
        
      });
  }

  public GetTown(): Promise<TownModel>{
    return this.storage.get(STORAGE_TOWN);
  }

  public SetTown(obj: TownModel): Promise<any>{
    obj.lastSync = new Date();
    this.communityService.Set(obj);
    return this.storage.set(STORAGE_TOWN, obj);
  }

  public async PushTownRequested(obj: TownSearchDTO): Promise<any>{
    const list = await this.storage.get(STORAGE_TOWNS_REQUESTED) ?? [];
    list.push(`${obj.codCa}-${obj.codPro}-${obj.codMun}`);
    return this.storage.set(STORAGE_TOWNS_REQUESTED, list);
  }
  public async GetTownRequested(obj: TownSearchDTO): Promise<boolean>{
    const list = await this.storage.get(STORAGE_TOWNS_REQUESTED) ?? [];
    const find = list.includes(`${obj.codCa}-${obj.codPro}-${obj.codMun}`);
    return find;
  }

  public GetTownParameters(): Observable<{}>{
    return this.communityService.GetParameters();
  }

  public SyncNeeded(date: Date){
    if (date){
      let maxLife = new Date().getTime() - (30 * 60 * 1000); // 30 minutes
      let diff = maxLife - new Date(date).getTime();
      let ret = (diff >= 0);
      return ret;
    }
    return true;
  }
}
