<div class="qty-input">
	<button 
        class="qty-count qty-count--minus" 
        type="button" 
        [disabled]="isDisabled || value == 0"
        (click)="add(-1)"
        >-</button>
	<input 
        class="product-qty" 
        type="number" 
        min="0" [max]="max"
        value="1" 
        [(ngModel)]="value"
        [disabled]="isDisabled" 
        />
	<button 
        class="qty-count qty-count--add" 
        type="button" 
        [disabled]="isDisabled || value == max"
        (click)="add(1)"
        >+</button>
</div>