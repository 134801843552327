import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationDTO } from 'src/app/models/NotificationDTO';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { DetailPage } from '../detail/detail.page';
import {CommunityService} from "../../../services/community/community.service";
import {first} from "rxjs/operators";
import { CommunityDTO } from 'src/app/models/CommunityDTO';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  
  @Input() firstLoad: BehaviorSubject<boolean>;
  @Input() refresh: EventEmitter<any>;
  @Input() segmentChanges: BehaviorSubject<boolean>;

  public isLoading = true;
  public isAuth = true;

  public mark_readed_button = false;

  private notificationsCounter = 0;

  public list = [];

  public refresher: any;

  public community: CommunityDTO;



  constructor(
    private api: ApiService,
    public router: Router,
    public modalController: ModalController,
    private auth: AuthService,
    private events: EventsService,
    private translate: TranslateService,
    private _communityService: CommunityService,
    private menu: MenuController
  ) { }

  ngOnInit() {
    
    this.refresh.subscribe(() => {
      this.isLoading = true;
      if (this.refresher) this.refresher.disabled = false;
      this.refreshNotifications();
    });
    
    this.segmentChanges.subscribe((val) => {
      this.isLoading = true;
      if (this.refresher) this.refresher.disabled = false;
      this.refreshNotifications();
    });

  }

  goToLogin(){
    this.menu.close();
    this.router.navigate(['/login']);
  }

  async refreshNotifications() {
    this.mark_readed_button = false;
    this.isAuth = await this.auth.isAuthenticated();
    
    if (this.isAuth) {
      this._communityService.Get().pipe(first()).subscribe(async town =>  {
        this.community = town;
        const res = await this.api.getUserNotificationsv2(town.id, !this.segmentChanges.value, 20, 0);
        await this.ProcessNewNotifications(res, true);
        this.isLoading = false;
      });

    } else {
      this.resetLists();
      this.isLoading = false;
    }
    
    return Promise.resolve();
  }

  resetLists() {
    this.notificationsCounter = 0;
    this.list = [];
  }

  async ProcessNewNotifications(res: Array<NotificationDTO>, refresh: boolean = false) {
    if (refresh) {
      this.resetLists();
    }

    this.notificationsCounter += res.length;
    await this.GroupNotificationsByList(res);

    if(this.segmentChanges.value && res.length == 0 && this.firstLoad.value) {
      this.firstLoad.next(false);
      this.segmentChanges.next(false);
    }

    this.mark_readed_button = this.segmentChanges.value && this.list.length > 0;
  }

  async open(n: NotificationDTO) {
    n.readByMe = true;
    const modal = await this.modalController.create({
      component: DetailPage,
      componentProps: {
        id: n.id
      }
    });
    return await modal.present();
  }

  private async GroupNotificationsByList(notifications: Array<NotificationDTO>) {

    const today = moment().startOf('day');

    let t = await this.translate.get(['TODAY', 'THIS_WEEK', 'THIS_MONTH', 'PREVIOUS']).toPromise();

    notifications
    .forEach(e => {
      const createDate = moment(e.createdAt).startOf('day');
      const diff = today.diff(createDate, 'days');
      
      if (diff < 1) {
        this.addNotificationToList('today', t['TODAY'], e);
      } else if (diff < 7) {
        this.addNotificationToList('week', t['THIS_WEEK'], e);
      } else if (diff < 30) {
        this.addNotificationToList('month', t['THIS_MONTH'], e);
      } else {
        this.addNotificationToList('old', t['PREVIOUS'], e);
      }
    });

  }

  private addNotificationToList(key: string, title: string, n: NotificationDTO) {    
    if (n.readByMe) {
      const group = this.list.find(x => x.key === key);

      if (group) {
        group.list.push(n);
      } else {
        this.list.push({ key, title, list: []});
        this.addNotificationToList(key, title, n);
      }
      
    } else {
      const group = this.list.find(x => x.key === key);

      if (group) {
        group.list.push(n);
      } else {
        this.list.push({ key, title, list: []});
        this.addNotificationToList(key, title, n);
      }
    }
  }

  readAll() {
    this.auth.isAuthenticatedObs().subscribe((auth) => {
      if (auth) {        
        const userId = this.auth.getUser().id;
        this.api.notification_MarkAllAsRead(this.community.id, userId)
          .then(() => {                        
            this.isLoading = true;
            this.refreshNotifications();            
            this.resetLists();            
            this.events.notificationsCountRefresh.emit();
          });
      }
    });

    this.mark_readed_button = false;
  }

  iconNameByType(type: number) {
    switch (type) {
      case 0: 
        return 'far fa-envelope'; //Message

      case 10: 
        return 'far fa-newspaper'; //New_Featured

      case 15: 
        return 'far fa-thumbs-up'; //ProposalPublished TODO improve

      case 20: 
      case 62:
        return 'far fa-thumbs-up'; //ProposalAproved

      case 21: 
      case 63:
        return 'far fa-times-circle'; //ProposalRejected

      case 22: 
      case 64:
        return 'icon-stopwatch'; //ProposalWaiting

      case 25: 
        return 'fas fa-chart-line'; //Proposal_Objective_Reached

      case 26: 
        return 'far fa-comment'; //ProposalNewComments

      case 30: 
        return 'fas fa-vote-yea'; //ConsultationPublished TODO improve

      case 31: 
        return 'fas fa-vote-yea'; //ConsultationClosed TODO improve

      //case 40: return 'fas fa-clipboard-check'; //AlertPending
      case 41: 
        return 'fas fa-clipboard-check'; //AlertSolved
      //case 42: return 'fas fa-clipboard-check'; //AlertDeleted
      case 43: 
        return 'icon-stopwatch'; //AlertWaiting
      case 44: 
        return 'far fa-times-circle'; //AlertRejected

      case 50: 
        return 'far fa-calendar'; //EventApproaching

      default: 
        return 'far fa-envelope'; //Message
    }
  }

  
  doRefresh(event) {
    this.refresher = event.target;

    this.refreshNotifications().then((res) => {
      this.refresher.complete();
    });
  }

  loadData(event) {
    this.refresher = event.target;

    if (this.notificationsCounter >= 100) {
      this.refresher.complete();
      this.refresher.disabled = true;
      return Promise.resolve();
    }

    return this.api.getUserNotifications(!this.segmentChanges.value, 20, this.notificationsCounter).then(async (res) => {
      await this.ProcessNewNotifications(res);
      this.isLoading = false;
      this.refresher.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (res.length === 0) {
        this.refresher.disabled = true;
      }
    });
  }

}
