<div class="comments-v3">
 
  <div class="empty" *ngIf="comments && !comments.length">
    {{'EMPTY_COMMENTS' | translate}}
  </div>
  
  <div class="comment" *ngFor="let c of comments" [class.creator]="c.isCreator">
    <!-- Comment Avatar -->
    <div class="comment-avatar">
      <img *ngIf="c.userImage" [src]="c.userImage | imageUrl | imageMode:'thumb'">
    </div>

    <!-- Comment Box -->
    <div class="comment-box">
      <div class="comment-head">
        <span class="comment-author">{{c.name}}</span>
        <span>{{c.createdAtString}}</span>
        <span *ngIf="c.isCreator" class="creator-label">{{'Coments.AUTHOR' | translate}}</span>
      </div>
      <div class="comment-text">
        {{c.message}}
      </div>
      <div class="comment-footer">
        <i class="fa fa-reply" *ngIf="isEnvDev"></i>

        <span *ngIf="isLogged" class="likes" (click)="likeUnlikeComment(c)"><span *ngIf="c.likes > 0">{{c.likes}}</span><i class="fa fa-heart" [class.active]="c.likedByMe"></i></span>
        
        <i class="fa fa-trash" *ngIf="userModel && userModel.username == c.userName" (click)="removeComment(c.id)"></i>
        <i class="fas fa-ellipsis-h" *ngIf="isEnvDev" (click)="showActions()"></i>
      </div>  
    </div>
  </div>

  <div class="comment form" *ngIf="userModel">

    <div class="comment-avatar">
      <img *ngIf="userModel.image" [src]="userModel.image | imageUrl | imageMode:'thumb'">
    </div>
  
    <div class="form-row">
      <textarea [placeholder]="('Coments.ADD_COMMENT' | translate) + '...'" required="" [(ngModel)]="newMessage"></textarea>
    </div>
    <div class="form-row">
      <ion-button expand="block" fill="outline" (click)="sendComment()">{{'SEND' | translate}}</ion-button>
    </div>
  
  </div>
</div>