// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'development',
  tag: 'dev',
  version: require('../../package.json').version,
};

export const API_URL = 'https://api.dev.wecoo.app';
//export const  API_URL = 'http://localhost:8080';
export const STORAGE_URL = 'https://storage.googleapis.com/wetown-test';
export const IMG_URL = 'https://storage.googleapis.com/wetown-test/imgs';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGZhcmdhcyIsImEiOiJja213bGkwbTYwZzJyMnJxc3dtanAxMHo0In0.2A_sd9EGwz7qL_pIdE_XdA';
export const STRIPE_PUBKEY = 'pk_test_51MskAiHLcumNZ77OD7GIpsqmRNjQ69FgNKS4jV8QtL3M8blJ5BSneDS15qRpQT7DDzrBlslsNPth53bxiZYvkJfV00sqZYnl47';
export const GOOGLE_TRANSLATE_KEY = 'AIzaSyAAifpssiqG4K7uQ5A86LQXBcfXO7_eG78';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

