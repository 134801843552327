import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuantityInputComponent),
    multi: true
  }]  
})
export class QuantityInputComponent implements OnInit, ControlValueAccessor  {
  
  @Input() max: number = 10;

  value: number;
  isDisabled: boolean;
  
  onChange = (_:any) => { }
  onTouch = () => { }

  constructor() { }

  writeValue(value: number): void {
    if(value != undefined){
      this.value = value;
    } else {
      this.value = 0;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {

  }

  public add(plus: number){
    this.value += plus;
    this.onChange(this.value);
  }

}
