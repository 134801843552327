import { Injectable, EventEmitter } from '@angular/core';
import { AnnouncementDTO } from '../models/AnnouncementDTO';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Browser } from '@capacitor/browser';
import { API_URL, environment } from 'src/environments/environment';
import { LoadingController, ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { ScanResultComponent } from '../pages/MODULES/ticketing/pages/qr-validator/scan-result/scan-result.component';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  
  public error = new EventEmitter<string>();
  public scanner = new EventEmitter<string>();

  private scanCallback: any;
  private removeCallbackOnExecute = true;

  public notificationsCountRefresh: EventEmitter<any> = new EventEmitter();
  public activateTutorial: EventEmitter<any> = new EventEmitter();
  public showPopUp: EventEmitter<AnnouncementDTO> = new EventEmitter();

  constructor(
    private router: Router,
    private http: HttpClient,
    private loadingCtrl: LoadingController,    
  ) { }

  async openUrl(url: string, title?: string) {

    if (url.startsWith('mailto:') || url.startsWith('tel:')){
      window.open(url);
      return;
    }

    /*
    var fallBack = () => {
      this.openCapacitorBrowser(url);
    };

    if (Capacitor.getPlatform() === 'android') {
      let loader = await this.loadingCtrl.create({ mode: 'ios' });
      loader.present();
  
      this.http.get(`${API_URL}/global/checkIframe?url=${url}`).subscribe((canOpenIframe) => {
        loader.dismiss();
        if (canOpenIframe) {
          let urlEncoded = encodeURIComponent(url);
          this.router.navigate(['webview'], { queryParams: { url: urlEncoded, title } })               
        } else {                        
          fallBack();
        }
      }, () => {
        loader.dismiss();
        fallBack();
      });
    } else {
      fallBack();
    }*/
    
    this.openCapacitorBrowser(url);
  }

  private async openCapacitorBrowser(url: string) {
    await Browser.open({ url });
  }

}
