import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { AlertModel } from 'src/app/models/AlertModel';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  @Input() id: number;
  incident: AlertModel;

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private nav: NavController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = parseInt(id);
    }
    
    this.api.Alert_GetById(this.id).then((res) => {
      this.incident = res;
      this.isLoading = false;
    });
  }

  async backStep() {
    const top = await this.modalCtrl.getTop();
    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }
}
