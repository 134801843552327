<ion-header class="no-shadow">
  <ion-toolbar>
    <ion-title class="ion-text-right">
      <ion-button *ngIf="isLogged" fill="clear" color="dark" (click)="openConfig()">
        <i class="fa fa-cog"></i>
      </ion-button> 
      {{'Notifications.NOTIFICATIONS' |translate}}
    </ion-title>
   
  </ion-toolbar>
  <ion-toolbar>
    <div class="w-100">
      <ion-segment mode="ios" (ionChange)="segmentChanged($event)" [(ngModel)]="segmentValue">
        <ion-segment-button value="new">
          <ion-label>{{'NOTIFICATIONS.NEW' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="readed">
          <ion-label>{{'NOTIFICATIONS.READED' | translate}}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-notifications-list [segmentChanges]="segmentChanges" [refresh]="refresh" [firstLoad]="firstLoad"></app-notifications-list>
</ion-content>