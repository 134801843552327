<ion-header no-border class="corp">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-sharp"></ion-icon> {{'Back' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-right">{{'LOCATION' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">
  <ion-grid class="ion-slide-container">
    <ion-row class="ion-padding-horizontal">
      <h1 class="ion-text-left">{{ 'CHANGE_LOCATION' | translate }}</h1>
      <div class="subtitle-text" [innerHtml]="'Register.YourZoneText' | translate"></div>
    </ion-row>
    <ion-row class="flex-grow">
      <app-location-selector 
      *ngIf="mapActivated"
      [marker-circle-radius]="80"
      (onCoordsChange)="coordsChange($event)"
      (onAddressChange)="addressChange($event)"
      (onMarkerInCityChange)="markerInCityChange($event)"
      ></app-location-selector>
    </ion-row>
  </ion-grid>

  <div class="button-wrapper">
    <ion-button type="submit" class="continue" expand="block" color="primary" (click)="onSubmit()"
        [disabled]="!coords || !isMarkerInCity">
        {{ 'CONTINUE' | translate }}
      </ion-button>
  </div>

</ion-content>
