<ion-header class="corp">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-sharp"></ion-icon> {{'Back' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'Consultations.CONSULTATION' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ng-container *ngIf="model">
    <ion-card>
      <img *ngIf="model.image" [src]="model.image | imageUrl:'consultations'" />
      <ion-card-header>
        <div class="ion-text-right ion-text-uppercase">
          <ng-container *ngIf="active"><small>{{'ENDS_IN' | translate}} {{model.RemainingTime}}</small></ng-container>
          <ng-container *ngIf="!active" ><small>{{'Consultations.CLOSED' | translate}}</small></ng-container>
        </div>
        <ion-card-title>{{model.title}}</ion-card-title>
        <ion-card-subtitle>{{model.startDate | date:'shortDate' }} - {{model.endDate | date:'shortDate' }}</ion-card-subtitle>
      </ion-card-header>
    
      <ion-card-content>
        <div class="description">
          {{model.description}}
        </div>

        <div *ngIf="model.url || model.files.length" class="ion-padding-top"></div>

        <ng-container *ngIf="model.url">
          <h3>{{ 'EXTRA_LINK' | translate }}</h3>
          <ul class="tiles-list-wrapper">
              <li (click)="openUrl(model.url)">
                <i class="fas fa-link main-icon"></i>
                <div class="file-info">
                  <div class="name">{{ model.url}}</div>
                </div>
                <i class="fas fa-external-link-alt right-icon"></i>
              </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="model.files.length">
          <h3>{{ 'ATTACHED_FILES' | translate }}</h3>
          <app-attached-files [files]="model.files"></app-attached-files>
        </ng-container>

      </ion-card-content>

      <div *ngIf="model.votes.total > 3" class="votes-summary ion-padding-horizontal">
        <div class="user-images">
          <div *ngFor="let img of model.votes.randProfilePictures" class="image-circle">
            <img [src]="img | imageUrl | imageMode:'thumb'">
          </div>
        </div>
        <div class="text" [innerHTML]="'Consultations.HAS_VOTED_THIS' | translate:{ num: model.votes.total }">
        </div>
      </div>
    </ion-card>
  
    <div class="answers-wrapper" [class.results]="results" [class.selectable]="selectable">
      <ion-radio-group [(ngModel)]="response">
        <ul class="answers">
          <li *ngFor="let a of model.answers" (click)="select(a.id)">     
            <div class="answer">
              <div class="radio">
                <ion-radio slot="start" [value]="a.id"></ion-radio>
              </div>
              <div class="description">
                <span>{{a.description}}</span>
              </div>
              <div class="percent">
                {{results? answersResults[a.id]?.percent : 0}}%
              </div>
            </div>

            <div class="bar answer" [style]="answersResults[a.id]?.style">              
              <div class="radio">
                <ion-radio slot="start" [value]="a.id"></ion-radio>
              </div>
              <div class="description">
                <span>{{a.description}}</span>
              </div>
              <div class="percent">
                {{results? answersResults[a.id]?.percent : 0}}%
              </div>              
            </div>                     
          </li>
        </ul>
      </ion-radio-group>
  
      <ion-button expand="block" shape="round" (click)="send()" [disabled]="!response" *ngIf="selectable">{{'SEND' | translate }}</ion-button>
      
      <ng-container *ngIf="active">
        <ion-button expand="block" fill="clear" (click)="changeVote()" [disabled]="!response" *ngIf="!selectable">{{'Consultations.DO_YOU_WANT_TO_CHANGE_YOUR_VOTE' | translate}}</ion-button>
      </ng-container>
      
    </div>

  </ng-container>

</ion-content>
