import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ListComponent } from './list.component';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

const routes: Routes = [
  {
    path: '',
    component: ListComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ApplicationPipesModule
  ],
  declarations: [ListComponent],
  exports: [
    ListComponent
  ]
})
export class ListComponentModule {}
