import { Moment } from 'moment';
import {AttachedFileDTO} from "./AttachedFileDTO";
import { DateTime } from 'luxon';
import { ExternalLinkDTO } from './ExternalLinkDTO';

export class EventDTO {

    public id: number;

    public name: string;
    public description: string;
    public dateFrom: string;
    public dateTo: string;
    public allDay: boolean;
    public locationString: string;
    public locationLat: number;
    public locationLng: number;
    public phone: string;
    public mail: string;

    public eventCategoryId: number;
    public eventCategoryName: string;
    public creatorId: number;

    public image: string;
    public images: string[];
    public files: AttachedFileDTO[];
    
    //Obsolete
    //public webScrapingSourceUrl: string;
    public webScrapingElementUrl: string;

    //Extensions
    public dateFrom_m?: Moment;
    public dateTo_m?: Moment;
    public color?: string;
    public imSubscribed: boolean;
    public admitSubscribers: boolean;

    public usersInscribe: number;
    public ticketId: number;

    public ticketSaleDateFrom: string;
    public ticketSaleDateTo: string;
    public lang: string;

    public externalLinks: ExternalLinkDTO[];

    constructor(){
    }

    public static sameDayHours(ev: EventDTO){
        if(ev.dateTo){
            let from = DateTime.fromISO(ev.dateFrom).startOf('day');
            let to = DateTime.fromISO(ev.dateTo).startOf('day');
            return from.equals(to);
        } else {
            return false;
        }
    }

}
