import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@capacitor/geolocation';
import { Injectable } from '@angular/core';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private locationAccuracy: LocationAccuracy,
    private translate: TranslateService
  ) { }

  async getLocation(showWarnings = true): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        console.log('Coords', coordinates);
        resolve(coordinates);
      } catch (error) {
        this.locationAccuracy.canRequest().then((canRequest: boolean) => {
          if (canRequest) {
            // the accuracy option will be ignored by iOS
            this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
              async () => {
                resolve(this.getLocation());
              },
              error => {
                console.error('Error requesting location permissions', error);
                if (showWarnings) this.presentNoPermissionsWarning();
                reject(error);
              }
            );
          } else {
            if (showWarnings) this.presentNoPermissionsWarning();
            reject();
          }

        }).catch(() => { 
          if (showWarnings) this.presentNoPermissionsWarning();
          reject();
        });
      }
    });
  }

  async presentWarning(result: string) {
    this.translate.get(['Oops', 'ACCEPT'])
      .subscribe(t => {
        Swal.fire({
          title: t.Oops,
          text: result,
          icon: 'warning',
          confirmButtonText: t.ACCEPT,
        });
      });
  }

  async presentNoPermissionsWarning() {
    this.translate.get('Geolocation.NO_PERMISSION').subscribe((t) => {
      this.presentWarning(t);
    });
    //this.isLocating = false;
  }

}
