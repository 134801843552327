<div *ngIf="element" class="card" [style.--color]="color" [class.event]="event" [class.ticket]="ticketPurchase" [class.disabled]="isDisabled">
  <div class="color-pill"></div>
  <div class="left-wrapper" [class.with-image]="element.image">
    <img *ngIf="element.image" [src]="element.image | imageUrl">

    <div class="overlay" *ngIf="event">
      <div class="daynumber">{{event.dateFrom_m.date()}}</div>
      <div class="month-name">{{event.dateFrom_m.format('MMMM')}}</div>
      <div class="hour" *ngIf="!event.allDay">{{event.dateFrom_m.format('LT')}}</div>
    </div>

    <div class="overlay" *ngIf="ticketPurchase">
      <div class="num">{{ticketPurchase.totalQuantity}}</div>
      <div class="text">{{ticketPurchase.totalQuantity == 1 ? 'Tiquet':'Tiquets'}}</div>
    </div>
  </div>
  <div class="info-wrapper">
    
    <div class="text-block">
      <div class="name">{{element.title}}</div>
      
      <div class="date-wrapper" *ngIf="event">
        <div class="date">
          <i class="fas fa-calendar-day fa-fw"></i> {{element.dateFrom | date:'L'}}
        </div>
        <div class="hour" *ngIf="!event.allDay">
          <i class="fas fa-clock fa-fw"></i> {{event.dateFrom_m.format('LT')}}
        </div>
      </div>

      <div class="date" *ngIf="ticketPurchase">
        {{ticketPurchase.createdAt | date:'dd/MM/yy'}}
      </div>
      <div class="date" *ngIf="element.dateFrom">
        {{element.dateFrom | date:'dd/MM/yy'}}
      </div>
      
      <div class="location" *ngIf="event && event.locationString">
        <i class="fas fa-map-marker-alt fa-fw"></i> {{event.locationString}}
      </div>

    </div>

    <div class="buttons-wrapper">
      <ion-button size="small" shape="round">{{buttonText}}</ion-button>
    </div>
  </div>
</div>