import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';
import { latLng } from 'leaflet';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertModel } from 'src/app/models/AlertModel';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { MapboxService } from 'src/app/services/mapbox.service';

@Component({
  selector: 'app-incident-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() incident: AlertModel;
  @Input() isPreview: boolean;

  locationString = '';

  public shareButtonAnimation: string;

  statusHistory: Array<any>;
  statusPercent: number;

  progressBarPercent: number;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private mapbox: MapboxService,
    public sanitizer: DomSanitizer,
    private analytics: AnalyticsService,
    private communityService: CommunityService
  ) { }

  ngOnInit() {

    if (this.incident.locationLat) {
      this.mapbox.reverseGeocode_GetStreetAndNumber(latLng(this.incident.locationLat, this.incident.locationLng))
      .then(res => {
        this.locationString = res;
      });
    }

  }

  ngAfterViewInit() {
   
    this.api.Alerts_GetStatusById(this.incident.id).then((res) => {
      this.processStatusHistory(res);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  processStatusHistory(history: Array<any>) {

    const res: Array<any> = [
      {
        id: 0,
        when: moment(this.incident.createdAt),
        text: this.getTextForStatusId(1),
        completed: true,
      }
    ];

    history.forEach(el => {
      res.push({
        id: el.statusId, 
        when: moment(el.when),
        text: this.getTextForStatusId(el.statusId),
        completed: true
      });
    });

    if (!res.find(x => x.id === 5 )) { //TODO hardcoded
      res.push({
        id: 5,
        text: this.getTextForStatusId(5), // Pendent
      });
    }

    if (!res.find(x => x.id === 10 || x.id === 15 || x.id === 20)) { //TODO hardcoded
      res.push({
        id: 40,
        text: this.getTextForStatusId(10), // Aprovada
      });
    }
    
    res.some((el, i) => {
      if (!el.completed && i > 0) {
        res[i - 1].current = true;
        return true;
      }
    });

    this.statusHistory = res;
    this.statusPercent = ((res.filter(x => x.completed).length - 1) / (res.length - 1)) * 100;
  }

  getTextForStatusId(statusId: number) {
    return this.translate.instant(`Incidents.Status.${statusId}`);
  }

  async share() {
    this.shareButtonAnimation = 'animate';

    setTimeout(() => {
      this.shareButtonAnimation = '';
    }, 1000);

    const t = await this.translate.get(['SHARE']).toPromise();
    const shareLink = await this.communityService.MakeShareLink('/incidents/' + this.incident.id);

    const shareRet = await Share.share({
      title: '',
      text: '',
      url: shareLink,
      dialogTitle: t.SHARE
    });

    console.log(shareRet);

    this.analytics.trackEvent('incidents', 'share');
  }

}
