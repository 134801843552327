import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QuantityInputComponent } from './quantity-input.component';

@NgModule({
  declarations: [
    QuantityInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule
],
  exports: [
    QuantityInputComponent
  ]
})
export class QuantityInputComponentModule {}