import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommentsComponent } from './comments.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

@NgModule({
  declarations: [
    CommentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ApplicationPipesModule
],
  exports: [
    CommentsComponent
  ]
})
export class CommentsComponentModule {}