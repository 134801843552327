import { EventDTO } from './../../models/EventDTO';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss'],
})
export class EventTileComponent implements OnInit {

  @Input() event: EventDTO;

  @Input() showDate?: moment.Moment;

  @Input() viewMode = 'MONTH';

  colors = ['#52A43A', '#BA68C8', '#F7AA17', '#EF5350'];

  public sameDayHours = false;

  constructor() { }

  ngOnInit() {
    this.event.dateFrom_m = moment(this.event.dateFrom);
    this.event.dateTo_m = moment(this.event.dateTo);
    
    if(!this.event.color){
      this.event.color = this.GetColorForEvent(this.event.id);
    }

    if(!this.showDate){
      this.showDate = this.event.dateFrom_m;
    }

    this.sameDayHours = EventDTO.sameDayHours(this.event);
  }

  GetColorForEvent(eventId: number) {
    return this.colors[eventId % this.colors.length];
  }

}
