import { PresentationGuard } from './guard/presentation.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) 
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [PresentationGuard]
  },
  {
    path: 'select-town',
    loadChildren: () => import('./pages/select-town/select-town.module').then(m => m.SelectTownPageModule),
  },
  {
    path: 'select-community',
    loadChildren: () => import('./pages/select-community/select-community.module').then(m => m.SelectCommunityPageModule),
  },
  {
    path: 'select-town/:mode',
    loadChildren: () => import('./pages/select-town/select-town.module').then(m => m.SelectTownPageModule),
  },
  {
    path: 'select-community/:mode',
    loadChildren: () => import('./pages/select-community/select-community.module').then(m => m.SelectCommunityPageModule),
  },
  { 
    path: 'user', 
    loadChildren: () => import('./pages/user/user-profile-new-version/user.module').then(m => m.UserProfilePageModule),
    canActivate: [AuthGuard],
  },
  { 
    path: 'presentation', 
    loadChildren: () => import('./pages/presentation/presentation.module').then(m => m.PresentationPageModule),
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/create/create.module').then(m => m.CreatePageModule),
    canActivate: [AuthGuard],
  },
  { 
    path: 'proposal-create',
    loadChildren: () => import('./pages/proposals/proposal-create/proposal-create.module').then(m => m.ProposalCreatePageModule), 
    canActivate: [AuthGuard],
  },
  { 
    path: 'proposal-vote', 
    loadChildren: () => import('./pages/proposals/proposal-vote/proposal-vote.module').then(m => m.ProposalVotePageModule) 
  },
  { 
    path: 'following-proposals', 
    loadChildren: () => import('./pages/following-proposals/following-proposals.module').then(m => m.FollowingProposalsPageModule) 
  },
  { 
    path: 'calendar', 
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule) 
  },
  { 
    path: 'news/:id', 
    loadChildren: () => import('./pages/news/news-detail/news-detail.module').then(m => m.NewsDetailPageModule), 
  },
  { 
    path: 'towns/:town/news/:id', 
    loadChildren: () => import('./pages/news/news-detail/news-detail.module').then(m => m.NewsDetailPageModule), 
  },
  { 
    path: 'developer', 
    loadChildren: () => import('./pages/developer/developer.module').then(m => m.DeveloperPageModule) 
  },
  { 
    path: 'notifications', 
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule) 
  },
  { 
    path: 'proposals/:id', 
    loadChildren: () => import('./pages/proposals/proposal-detail/proposal-detail.module').then(m => m.ProposalDetailPageModule) 
  },
  { 
    path: 'notification/:id', 
    loadChildren: () => import('./pages/notifications/detail/detail.module').then(m => m.DetailPageModule) 
  },
  { 
    path: 'consultations/:id', 
    loadChildren: () => import('./pages/consultations/detail/detail.module').then(m => m.DetailPageModule) 
  },
  { 
    path: 'alert-create', 
    redirectTo: 'incidents/create'
  },
  { 
    path: 'legal/:type', 
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule) 
  },
  { 
    path: 'register', 
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) 
  },
  { 
    path: 'calendar/events/:id', 
    loadChildren: () => import('./pages/calendar/detail/detail.module').then(m => m.DetailPageModule) 
  },
  { 
    path: 'events/:id', 
    loadChildren: () => import('./pages/calendar/detail/detail.module').then(m => m.DetailPageModule) 
  },
  { 
    path: 'pass-recovery', 
    loadChildren: () => import('./pages/pass-recovery/pass-recovery.module').then(m => m.PassRecoveryPageModule) 
  },
  { 
    path: 'user/change-avatar', 
    loadChildren: () => import('./pages/user/change-avatar/change-avatar.module').then(m => m.ChangeAvatarPageModule) 
  },
  {
    path: 'user/edit',
    loadChildren: () => import('./pages/user/edit/edit.module').then(m => m.EditPageModule)
  },
  {
    path: 'user/notifications',
    loadChildren: () => import('./pages/user/notification-config/notification-config.module').then(m => m.NotificationConfigPageModule)
  },
  { path: 'user/networking-config',
    loadChildren: () => import('./pages/user/networking-config/networking-config.module').then(m => m.NetworkingConfigPageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'change-location', 
    loadChildren: () => import('./pages/user/change-location/change-location.module').then(m => m.ChangeLocationPageModule) 
  },
  {
    path: 'my-events',
    loadChildren: () => import('./pages/my-events/my-events.module').then(m => m.MyEventsPageModule)
  },
  { 
    path: 'info-aj',
    redirectTo: 'info-community'
  },
  { 
    path: 'info-community', 
    loadChildren: () => import('./pages/info-community/info-community.module').then(m => m.InfoCommunityPageModule) 
  },
  {
    path: 'community-selector',
    loadChildren: () => import('./pages/community-selector/community-selector.module').then(m => m.CommunitySelectorPageModule)
  },
  {
    path: 'brigades',
    loadChildren: () => import('./pages/incidents/brigades/brigades.module').then(m => m.BrigadesModule) 
  },
  {
    path: 'modules',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesPageModule),
      },
      {
        path: ':moduleId',
        children: [
          {
            path: 'list',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/resources/items-list/items-list.module').then(m => m.ItemsListPageModule),
              },
              {
                path: ':id',
                loadChildren: () => import('./pages/resources/items-list/detail/detail.module').then(m => m.DetailPageModule),
              }
            ]
          },
          {
            path: 'map',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/resources/map-list/map-list.module').then(m => m.MapListModule),
              },
              {
                path: ':id',
                loadChildren: () => import('./pages/resources/items-list/detail/detail.module').then(m => m.DetailPageModule)
              }
            ]
          },
          { 
            path: 'html', 
            loadChildren: () => import('./pages/resources/html/html.resource.page.module').then(m => m.HtmlResourcePageModule) 
          },
        ]
      }
    ]
  },
  {
    path: 'resources',
    children: [
      {
        path: '',
        //loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesPageModule),
        redirectTo: '/modules',
        pathMatch: 'full'
      },
      {
        path: 'pois',
        redirectTo: 'list',
      },
      {
        path: 'list',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/resources/items-list/items-list.module').then(m => m.ItemsListPageModule)
          },
          {
            path: ':id',
            //loadChildren: () => import('./pages/resources/items-list/detail/detail.module').then(m => m.DetailPageModule),
            redirectTo: '/modules/-/list/:id',
          }
        ]
      },
      {
        path: 'map-list',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/resources/map-list/map-list.module').then(m => m.MapListModule),
          },
          {
            path: ':id',
            //loadChildren: () => import('./pages/resources/items-list/detail/detail.module').then(m => m.DetailPageModule)
            redirectTo: '/modules/-/map/:id',
          }
        ]
      },      
      {
        path: 'voluminous',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/voluminous-pickup/voluminous.module').then(m => m.VoluminousModule)
          },
          {
            path: 'types',
            loadChildren: () => import('./pages/voluminous-pickup/voluminous.module').then(m => m.VoluminousModule)
          },
          {
            path: 'form',
            canActivate: [AuthGuard],
            loadChildren: () => import('./pages/voluminous-pickup/voluminous.module').then(m => m.VoluminousModule)            
          }
        ]
      },
      {
        path: 'proposals',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/resources/proposals/proposals.module').then(m => m.ProposalsModule),
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'incidents',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/resources/incidents/incidents.module').then(m => m.IncidentsModule),
            canActivate: [AuthGuard],
          }
        ]
      },      
      {
        path: 'news',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule),
          }
        ]
      },
      {
        path: 'calendar',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
          }
        ]
      },
      { 
        path: 'selective_waste', 
        loadChildren: () => import('./pages/resources/selective-waste/selective-waste.module').then(m => m.SelectiveWastePageModule) 
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesPageModule),
      }
    ]
  },
  { 
    path: 'announcements/:id', 
    loadChildren: () => import('./pages/announcements/detail/detail.module').then(m => m.DetailPageModule)
  },
  { 
    path: 'mailbox/send', 
    loadChildren: () => import('./pages/MODULES/mailbox/send/mailbox-send.module').then(m => m.MailBoxSendPageModule),
    canActivate: [AuthGuard],
  },
  { path: 'proposals', loadChildren: () => import('./pages/resources/proposals/proposals.module').then(m => m.ProposalsModule) },
  { 
    path: 'incidents', 
    children : [
      { 
        path: '', 
        loadChildren: () => import('./pages/resources/incidents/incidents.module').then(m => m.IncidentsModule)
      },
      { 
        path: 'create', 
        loadChildren: () => import('./pages/incidents/create/incident-create.module').then(m => m.IncidentCreatePageModule),
        canActivate: [AuthGuard],
      },
      { 
        path: ':id', 
        loadChildren: () => import('./pages/incidents/detail/detail.module').then(m => m.DetailPageModule) 
      }
    ]
  },
  { 
    path: 'tickets', 
    children : [
      { 
        path: '', 
        loadChildren: () => import('./pages/MODULES/ticketing/pages/tickets/tickets.module').then(m => m.TicketsModule),
      },
      { 
        path: 'my-tickets', 
        canActivate: [AuthGuard],
        children: [
          { 
            path: ':uuid', 
            loadChildren: () => import('./pages/MODULES/ticketing/pages/ticket-qr/ticket-qr.page.module').then(m => m.TicketQrPageModule),
          },
          {
            path: '', 
            loadChildren: () => import('./pages/MODULES/ticketing/pages/my-tickets/my-tickets.page.module').then(m => m.MyTicketsPageModule),
          },
        ]
      },
      { 
        path: ':id', 
        loadChildren: () => import('./pages/MODULES/ticketing/pages/tickets/ticket-detail/ticket-detail.page.module').then(m => m.TicketDetailPageModule),
      },
    ]
  },
  {
    path: 'inscriptions',
    loadChildren: () => import('./pages/MODULES/inscriptions/inscriptions.module').then(m => m.InscriptionsModule)
  },
  { path: 'community-selector', loadChildren: () => import('./pages/community-selector/community-selector.module').then(m => m.CommunitySelectorPageModule) },
  { path: 'admin-options', loadChildren: () => import('./pages/admin-options/admin-options.module').then(m => m.AdminOptionsPageModule) },
  { 
    path: 'networking', 
    canActivate: [AuthGuard],
    children: [
      { 
        path: '', 
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/MODULES/networking/networking.module').then(m => m.NetworkingPageModule),
      },
      { 
        path: 'my-profile', 
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/user/networking-config/networking-config.module').then(m => m.NetworkingConfigPageModule),
      },
      { 
        path: ':id', 
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/MODULES/networking/networking-detail/networking-detail.module').then(m => m.NetworkingDetailPageModule),
      }
    ]
  },
  {
    path: 'webview', 
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/web-view/web-view.module').then(m => m.WebViewModule),
      }
    ]    
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
