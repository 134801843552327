import { NotificationCategoryModel } from './../models/NotificationCategoryModel';
import { AnnouncementDTO } from './../models/AnnouncementDTO';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ProposalModel } from '../models/ProposalModel';
import { UserModel } from '../models/UserModel';
import { AuthService } from '../auth/auth.service';
import { API_URL } from 'src/environments/environment';
import { NotificationDTO } from '../models/NotificationDTO';
import { ConsultationDTO } from '../models/ConsultationDTO';
import Swal from 'sweetalert2';
import { RegisterDTO } from '../models/RegisterDTO';
import { AlertModel } from '../models/AlertModel';
import { CommentDTO } from '../models/CommentDTO';
import { EventDTO } from '../models/EventDTO';
import { UserUpdateDTO } from '../models/User/UserUpdateDTO';
import { TranslateService } from '@ngx-translate/core';
import { ReactionType } from 'src/constants';
import { NewDTO } from '../models/NewDTO';
import { Observable } from 'rxjs';
import { TownModel } from '../models/TownModel';
import { TownSearchDTO } from '../models/TownSearchDTO';
import { TownRequestDTO } from '../models/requests/TownRequestDTO';
import { CommunityModuleDTO } from '../models/CommunityModules/CommunityModuleDTO';
import { DeviceRegistrationDTO } from '../models/DeviceRegistrationDTO';
import { MailBoxDTO } from '../models/MailBoxDTO';
import {NetworkingDataDTO} from "../models/NetworkingDataDTO";
import { AlertActivityDTO } from '../models/AlertActivityDTO';
import { ModuleItemDTO } from '../models/CommunityModules/ListModule/ModuleItemDTO';
import { VoluminousPickupDTO } from '../models/VoluminousPickupDTO';
import { InscriptionListDTO } from '../models/inscriptions/InscriptionListDTO';
import { InscriptionDTO } from '../models/inscriptions/InscriptionDTO';
import { BonificationDTO } from '../models/inscriptions/BonificationDTO';
import { RecurrenceDTO } from '../models/inscriptions/RecurrenceDTO';
import { InscriptionSentDTO } from '../models/inscriptions/InscriptionSentDTO';

const PARAMETER_GLOBAL = 0;
const PARAMETER_TOWN = 1;
const PARAMETER_USER = 2;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private  http: HttpClient,
    private auth: AuthService,
    private translate: TranslateService
    ) { }

  public get(url: string) { 
    url = this.addParameters(url);
    //console.log(['GET', url]);
    
    const p = new Promise<any>((resolve, reject) => {
      this.http.get(url)
        .subscribe(data => {
          //console.log(['GET RESPONSE', url, 'Response:', data]);
          this.CheckResponse(url, data, resolve, reject);
        }, err => {
          this.errorHandler(err);
          reject(err);
        });
    });

    return p;
  }

  public put(url: string, data: any) {
    url = this.addParameters(url);
    console.log(['PUT', url]);

    const p = new Promise<any>((resolve, reject) => {
      this.http.put(url, data,
        {
          headers: { 'Content-Type': 'application/json' }
        })
        .subscribe(data2 => {
          console.log(['PUT RESPONSE', url, 'Response:', data2]);
          this.CheckResponse(url, data2, resolve, reject);
        }, err => {
          this.errorHandler(err);
          reject(err);
        });
    });

    return p;
  }

  public post(url: string, data: any) {
    url = this.addParameters(url);
    console.log(['POST', url, data]);

    const p = new Promise<any>((resolve, reject) => {
      this.http.post(url, data)
        .subscribe(responseData => {
          console.log(['POST RESPONSE', url, 'Response:', responseData]);
          this.CheckResponse(url, responseData, resolve, reject);
        }, err => {
          this.errorHandler(err);
          reject(err);
        });
    });

    return p;
  }

  public delete(url: string) { 
    url = this.addParameters(url);
    console.log(['DELETE', url]);
    
    const p = new Promise<any>((resolve, reject) => {
      this.http.delete(url)
        .subscribe(data => {
          console.log(['DELETE RESPONSE', url, 'Response:', data]);
          this.CheckResponse(url, data, resolve, reject);
        }, err => {
          this.errorHandler(err);
          reject(err);
        });
    });

    return p;
  }

  private CheckResponse(url: string, res: any, resolveHandler: (value?: any) => void, rejectHandler: (value?: any) => void) {
    resolveHandler(res);
  }

  private addParameters(url: string): string {
    const urlObj = new URL(url);    

    return urlObj.toString();
  }

  private async errorHandler(err: HttpErrorResponse) {
    console.error(err);
    //this.events.publish('ERROR', 'Error de conexión', Date.now());
    switch (err.status) {
      case 401:
      case 403:
        this.auth.refreshToken();
        break;
      default:
        const t = await this.translate.get(['Oops', 'Errors.GENERIC', 'ACCEPT']).toPromise();
        Swal.fire({
          title: t.Oops,
          text: t['Errors.GENERIC'],
          icon: 'error',
          confirmButtonText: t.ACCEPT,
        });
    }
  }

  /*
  private GetHeaders(): HttpHeaders{
      //headers: { 'Content-Type': 'application/json' }
      //headers: { 'Content-Type': 'multipart/form-data' }
      //headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    let auth = this.injector.get(AuthService);

    if(auth.isAuthenticated()){
      return new HttpHeaders({
        'Authorization': "Bearer " + auth.getToken()
      });
    }else{
      return new HttpHeaders({
        
      });
    }
  }
  */

  //#region User

  register(model: RegisterDTO) {
    return this.http.post(`${API_URL}/users/register`,
      model
    );
  }

  checkEmailAvailability(email: string) {
    return this.get(`${API_URL}/Users/checkEmailAvailability?email=${email}`);
  }

  getUser(): Promise<UserModel> {
    return this.get(`${API_URL}/users/me`);
  }

  getUserEmailConfirmation(): Promise<UserModel> {
    return this.get(`${API_URL}/users/checkEmailConfirmation`);
  }

  User_Update(model: UserUpdateDTO): Promise<UserModel> {
    return this.put(`${API_URL}/users/me`,
      model
    );
  }

  User_setLang(lang: string): Promise<void> {
    return this.put(`${API_URL}/users/lang?lang=${lang}`, null);
  }

  recoverPassword(email: string) {
    return this.get(`${API_URL}/Users/recoverPassword?email=${email}`);
  }

  resendConfirmationEmail() {
    return this.get(`${API_URL}/Users/resendConfirmationEmail`);
  }

  public deleteUser(userId: number) {
    return this.delete(`${API_URL}/Users/delete/${userId}?deleteNow=false`);
  }

  //#endregion

  //#region Towns

  SearchCommunitiesByName(txt: string) {
    return this.get(`${API_URL}/v2/Communities/search?text=${txt}`);
  }
  SearchTownsByName(txt: string, top = 10): Promise<TownSearchDTO[]> {
    return this.get(`${API_URL}/Towns/search?text=${txt}&$top=${top}&$orderby=townUsersCount desc`);
  }

  Town_GetById(id: number): Promise<TownModel> {
    return this.get(`${API_URL}/v2/Communities/${id}`);
  }
  GetMyTowns(community:number): Promise<TownModel[]> {
    return this.get(`${API_URL}/v2/Communities/${community}/my/`);
  }
  DeleteTown(communityId:number): Promise<TownModel[]> {
    return this.get(`${API_URL}/Towns/${communityId}/deleteTownUser`);
  }

  Town_GetByCode(code: string): Promise<TownModel> {
    return this.get(`${API_URL}/v2/Communities/${code}`);
  }

  Town_GetModules(id: number): Promise<CommunityModuleDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${id}/modules`);
  }

  
  Town_GetModule(townId: number, moduleKey: string): Promise<CommunityModuleDTO> {
    return this.get(API_URL + `/v2/Communities/${townId}/modules/${moduleKey}`);
  }
  Town_GetModuleById(townId: number, moduleId: number): Promise<CommunityModuleDTO> {
    return this.get(API_URL + `/v2/Communities/${townId}/modules/${moduleId}`);
  }
  GetChildrenModulesByModuleId(townId: number, moduleId: number): Promise<CommunityModuleDTO[]> {
    return this.get(API_URL + `/v2/Communities/${townId}/modules/${moduleId}/children-modules`);
  }
  SetUserToAnotherCommunity(townId: number): Promise<CommunityModuleDTO> {
    return this.get(API_URL + `/v2/Communities/${townId}/AddNewCommunity`);
  }

  public Town_GetGeoTown(id: number): Promise<any> {
    return this.get(`${API_URL}/v2/Communities/${id}/geoJson`);
  }

  public Town_Request(req: TownRequestDTO): Promise<any> {
    return this.post(`${API_URL}/Towns/request`, req);
  }

  public getGroupByCif(communityCode: string, cif: string) {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/groups/getByCif?cif=${cif}`);
  }

  //#endregion

  //#region News

  getNews(town: number, top: number, skip: number): Promise<Array<NewDTO>> {
    return this.get(`${API_URL}/v2/Communities/${town}/news?$top=${top}&$skip=${skip}&$orderby=publishDate desc`);
  }

  getNew(town: number, id: number) {
    return this.get(`${API_URL}/v2/Communities/${town}/news/${id}`);
  }

  getFeaturedNews(town: number) {
    return this.get(`${API_URL}/v2/Communities/${town}/news/featured`);
  }

  //#region Networking

  getListUsers(town: number): Promise<Array<UserModel>> {
    return this.get(`${API_URL}/Networking/listUsersNetworking?townId=${town}`);
  }
  getUserByIdAndCommunity(town: number, userId: number): Promise<UserModel> {
    return this.get(`${API_URL}/Networking/getUserInformation?townId=${town}&followId=${userId}`);
  }

  checkFavorite( userFollowed: number): Promise <any> {
    return this.get(`${API_URL}/Networking/checkFavorite?userIdFollowed=${userFollowed}`);
  }


  //#endregion

  //#region DNI

  getDNIFromPhoto(image: string, townId?: number): Observable<any> {
    const body: any = {
      data: image
    };

    if (townId) {
      body.town = townId;
    }

    return this.http.post(`${API_URL}/users/ScanDNI`, JSON.stringify(body), {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getDNIFromPhotoTEST(urlAPI: string, image: string) {
    const body = JSON.stringify( {
      data: image
    });
    return this.http.post(urlAPI, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  //#endregion

  //#region Proposals

  getProposalsCategoriesByTown(town: number): Promise<Array<any>> {
    return this.get(API_URL + `/proposals/categories?townId=${town}`);
  }

  getProposalsStatus() {
    return this.get(`${API_URL}/proposals/status`);
  }

  createProposal(proposal: ProposalModel) {
    const body = JSON.stringify(proposal);
    return this.post(`${API_URL}/proposals`, body);
  }

  putProposalDraft(proposal: ProposalModel) {
    const body = JSON.stringify(proposal);
    return this.put(`${API_URL}/proposals/draft`, body);
  }

  publishProposalDraft(id: number) {
    return this.post(`${API_URL}/proposals/${id}/publish`, null);
  }
  public SetNetworkingData(model: NetworkingDataDTO){
    return this.post(`${API_URL}/Networking/networkingData`, model);

  }
  public GetNetworkingData(): Promise<NetworkingDataDTO> {
    return this.get(`${API_URL}/Networking/getNetworkingData`);
  }
  
  getProposalsToVote(town: number) {
    return this.get(`${API_URL}/proposals/pendingToVote?town=${town}`);
  }

  getProposal(id: number): Promise<ProposalModel> {
    return this.get(`${API_URL}/proposals/${id}`);
  }

  voteProposal(id: number, type: number) {
    return this.post(`${API_URL}/proposals/${id}/vote?type=${type}`, null);
  }

  proposal_Follow(id: number) {
    return this.post(`${API_URL}/proposals/${id}/favorite`, null);
  }

  proposal_Unfollow(id: number) {
    return this.delete(`${API_URL}/proposals/${id}/favorite`);
  }

  proposal_report(id: number) {
    return this.get(`${API_URL}/proposals/${id}/report`);
  }

  getProposalsFollowing() {
    return this.get(`${API_URL}/proposals/favorite?&$orderby=createdAt desc`);
  }

  getProposalsRecentlyVoted(length: number = 5) {
    return this.get(`${API_URL}/proposals/recentlyVoted?length=${length}`);
  }

  getProposalsMostVoted(town: number, length: number = 3) {
    return this.get(`${API_URL}/proposals/MostVoted?town=${town}&length=${length}`);
  }

  getTopProposalsByStatus(town: number, top: number, statusId: number): Promise<Array<ProposalModel>> {
    return this.get(`${API_URL}/proposals/top?town=${town}&$top=${top}&$filter=statusId eq ${statusId}&$orderby=votes_Balance desc,createdAt desc`);
  }

  getTopProposals(town: number, top: number): Promise<Array<ProposalModel>> {
    return this.get(`${API_URL}/proposals/top?town=${town}&$top=${top}&$filter=statusId in (20,30,40)&$orderby=statusId,votes_Balance desc,createdAt desc`);
  }

  getProposalsCreated() {
    return this.get(`${API_URL}/proposals/created`);
  }

  getProposalsStatusHistory(id: number) {
    return this.get(`${API_URL}/Proposals/${id}/status?includeNotCompleted=true`);
  }

  getProposalsComments(id: number): Promise<Array<CommentDTO>> {
    return this.get(`${API_URL}/Proposals/${id}/comments`);
  }
  
  postProposalComment(id: number, message: string) {
    return this.post(`${API_URL}/Proposals/${id}/comment`, {message});
  }

  deleteProposalsComment(proposalId: number, commentId: number) {
    return this.delete(`${API_URL}/Proposals/${proposalId}/comment/${commentId}`);
  }

  //#endregion

  //#region Notifications
  
  notification_registerDevice(dto: DeviceRegistrationDTO) {
    return this.post(`${API_URL}/users/registerDevice`, dto);
  }

  notification_unregisterDevice(registrationId: string) {
    return this.delete(`${API_URL}/users/registerDevice?RegistrationId=${registrationId}`);
  }


  getUserNotifications(readed: boolean, top: number, skip: number): Promise<Array<NotificationDTO>> {
    return this.get(`${API_URL}/notifications?$top=${top}&$skip=${skip}&$filter=readByMe eq ${readed}&$orderby=createdAt desc`);
  }
  getUserNotificationsv2(communityCode: number, readed: boolean, top: number, skip: number): Promise<Array<NotificationDTO>> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Notifications/MyNotifications?$top=${top}&$skip=${skip}&$filter=readByMe eq ${readed}&$orderby=createdAt desc`);
  }

  geNotificationById(id: number): Promise<NotificationDTO> {
    return this.get(`${API_URL}/notifications/${id}`);
  }

  notification_MarkAsRead(id: number) {
    return this.post(`${API_URL}/notifications/${id}/markAsRead`, null);
  }
  notification_MarkAllAsRead(communityId: number, userId: number) {
    return this.post(`${API_URL}/notifications/${communityId}/${userId}/markAllAsRead`, null);
  }
  getNotificationsCategoriesActive(townId: number): Promise<Array<NotificationCategoryModel>> {
    return this.get(`${API_URL}/notifications/${townId}/categoriesActive`);
  }

  UpdateNotificationsCategories(townId: number , notiConfig: NotificationCategoryModel) {
    
    return this.put(`${API_URL}/notifications/${townId}/UpdateCategory`, notiConfig);
  }


  /**
   * @deprecated The method should not be used
   */
  getPopupNotification(townId: number): Promise<NotificationDTO> {
    return this.get(`${API_URL}/Towns/${townId}/popup`);
  }

  getActiveAnnouncements(townId: number, type: string): Promise<Array<AnnouncementDTO>> {
    return this.get(`${API_URL}/Announcements/Active?townId=${townId}&type=${type}`);
  }

  getAnnouncementById(id: number): Promise<AnnouncementDTO> {
    return this.get(`${API_URL}/Announcements/${id}`);
  }

  Announcement_registerViewById(id: number): Promise<AnnouncementDTO> {
    return this.post(`${API_URL}/Announcements/${id}/registerView`, null);
  }
  //#endregion

  //#region Consultations

  getConsultations(townID: number): Promise<Array<ConsultationDTO>> {
    return this.get(API_URL + `/consultations/Active?townId=${townID}`);
  }

  getConsultationById(id: number): Promise<ConsultationDTO> {
    return this.get(API_URL + `/consultations/${id}`);
  }

  voteConsultation(id: number, answerId: number): Promise<ConsultationDTO> {
    return this.put(API_URL + `/Consultations/${id}/vote/${answerId}`, null);
  }

  getConsultationResults(id: number): Promise<ConsultationDTO> {
    return this.get(API_URL + `/Consultations/${id}/results`);
  }


  //#endregion

  //#region Alerts

  Alerts_Create(model: AlertModel) {
    const body = JSON.stringify(model);
    return this.http.post(`${API_URL}/Alerts`, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  Alert_GetById(id: number): Promise<AlertModel> {
    return this.get(API_URL + `/Alerts/${id}`);
  }

  Alerts_GetStatusById(id: number): Promise<Array<any>> {
    return this.get(API_URL + `/Alerts/${id}/status`);
  }

  Alerts_GetCategoriesByTown(town: number): Promise<Array<any>> {
    return this.get(API_URL + `/Alerts/categories?townId=${town}`);
  }

  Alerts_GetByUser(): Promise<Array<AlertModel>> {
    return this.get(API_URL + `/Alerts/created`);
  }

  //#endregion

  //#region Events
  Events_GetByTown(town: number, start: string, end: string): Promise<Array<EventDTO>> {
    return this.get(API_URL + `/Events?townId=${town}&start=${start}&end=${end}&$orderby=dateFrom`);
  }

  Events_GetById(id: number): Promise<EventDTO> {
    return this.get(API_URL + `/Events/${id}`);
  }
  SubscribeEvents( eventId: number) {
    return this.post(API_URL + `/Events/${eventId}/subscribe/` , null);
  }
  UnSubscribeEvents( eventId: number) {
    return this.post(API_URL + `/Events/${eventId}/unSubscribe/` , null);
  }

  GetMyEvents(): Promise<EventDTO[]>{
    return this.get(API_URL + `/Events/MyEvents`);
  }
  //#endregion

  //#region Files

  Files_uploadImage(formData: FormData, category: number | string, townId?: number): Promise<string> {
    
    let params = new HttpParams()
      .set('category', category.toString());
      
    if (townId) {
      params = params.set('townId', townId.toString());
    }

    const p = new Promise<any>((resolve, reject) => {
      this.http.post(API_URL + `/Files/uploadImage?${params.toString()}`, formData,
        {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, err => {
          this.errorHandler(err);
          reject(err);
        });
    });

    return p;
  }

  //#endregion

  //#region MailBox

  sendMailBox(dto: MailBoxDTO) {
    return this.post(`${API_URL}/mailbox/send`, dto);
  }

  //#endregion

  //#region REACTIONS
  
  Reactions_Get(type: ReactionType, id: number): Promise<any> {
    return this.get(`${API_URL}/Reactions?type=${type}&contentId=${id}`);
  }

  Reactions_Set(type: ReactionType, id: number, reaction: number): Promise<any> {
    const body: any = {
      reactionsType: type,
      contentId: id,
      reaction
    };

    return this.post(`${API_URL}/Reactions`, body);
  }
  
  Reactions_Like_Set(type: ReactionType, id: number, like: boolean): Promise<any> {

    const body: any = {
      reactionsType: type,
      contentId: id,
      like
    };

    return this.post(`${API_URL}/Reactions`, body);
  }

  //#endregion

  //#region RESOURCES

  Resources_GetByTown(id: number): Promise<Array<any>> {
    return this.get(API_URL + `/v2/Communities/${id}/resources`);
  }

  //#endregion

  //#region RESOURCES

  Pois_GetByTown(id: number, categoryId?: number, type: number = null): Promise<Array<ModuleItemDTO>> {
    
    let url = `/v1/ModuleItems?townId=${id}`;

    if (type){
      url += `&type=${type}`;
    }

    if (categoryId){
      url += `&$filter=categoryId eq ${categoryId}`;
    }

    return this.get(API_URL + url);
  }

  Pois_GetById(id: number): Promise<any> {
    return this.get(API_URL + `/v1/ModuleItems/${id}`);
  }

  
  //#region SELECTIVE_WASTE

  TownModules_SelectiveWaste(townCode: string): Promise<Array<any>> {
    return this.get(API_URL + `/TownModules/SelectiveWaste?townCode=${townCode}`);
  }

  //#endregion


  //#endregion

  //#region PARAMETERS

    public getGlobalParameter(parameterName: string) {
      return this.http.get(`${API_URL}/parameters?type=${PARAMETER_GLOBAL}&paramName=${parameterName}`,
      { responseType: 'text'});
    }
  
    public getTownParameter(parameterName: string, townId: number) {
      return this.http.get(`${API_URL}/parameters?type=${PARAMETER_TOWN}&paramName=${parameterName}&townId=${townId}`,
      { responseType: 'text'});
    }
  
    public getUserParameter(parameterName: string, userId: number, townId: number, moduleId: number = null) {
      let url = `${API_URL}/parameters?type=${PARAMETER_USER}&paramName=${parameterName}&userId=${userId}&townId=${townId}`;
      if (moduleId){
        url += `&moduleId=${moduleId}`;
      }
      return this.http.get(url, { responseType: 'text'});
    }
  
    /*Set Parameters*/
    public setGlobalParameter(parameterName: string, value: any) {
      return this.http.post<any>(`${API_URL}/parameters?type=${PARAMETER_GLOBAL}&paramName=${parameterName}&value=${value}`, null);
    }
  
    public setTownParameter(parameterName: string, townId: number, value: any) {
      return this.post(`${API_URL}/parameters?type=${PARAMETER_TOWN}&paramName=${parameterName}&value=${value}&townId=${townId}`, null);
    }
    
  public setUserParameter(parameterName: string, userId: number, value: any, townId: number, moduleId: number = null) {
      let url = `${API_URL}/parameters?type=${PARAMETER_USER}&paramName=${parameterName}&value=${value}&userId=${userId}&townId=${townId}`;
      if (moduleId){
        url += `&moduleId=${moduleId}`;
      }
      return this.post(url, null);
    }
  //#endregion

  //#region BRIGADES

    public getIncidentsForAdmin() {
      const url = `${API_URL}/alerts/brigades-incidents`;
      return this.get(url);
    }

    public getIncidentForAdmin(id: number) {
      const url = `${API_URL}/alerts/brigades-incident?id=${id}`;
      return this.get(url);
    }

    public getIncidentsInternalStatus() {
      const url = `${API_URL}/alerts/statusInternal`;
      return this.get(url);
    }

    public createIncidentActionReport(model: AlertActivityDTO){
      const url = `${API_URL}/alerts/brigades-incidents`;
      return this.post(url, model);
    }

    public getIncidentsHistoric(id: number) {      
      const url = `${API_URL}/Alerts/${id}/activity`;
      return this.get(url);
    }

    public getIncidentsStatus() {
      const url = `${API_URL}/alerts/status`;
      return this.get(url);
    }

  //#endregion

  //#region VOLUMINOUS

  public getVoluminousTypes(communityId: number) {
    let url = `${API_URL}/voluminous/appVoluminousTypes?communityId=${communityId}`;
    return this.get(url);
  }

  public createVoluminousPickup(model: VoluminousPickupDTO) {    
    let url = `${API_URL}/voluminous/createPickup`;
    return this.post(url, model);
  }

  public getMyVoluminousPickups(communityId: number, skip: number) {
    let url = `${API_URL}/voluminous/${communityId}/myVoluminousPickups?skip=${skip}`;
    return this.get(url);
  }

  //#endregion

  //#region INSCRIPTIONS 

  public getInscriptionsList(communityCode: string): Promise<InscriptionListDTO[]> {
    const url = `${API_URL}/v2/communities/${communityCode}/modules/inscriptions/inscriptions-list`;
    return this.get(url);
  }

  public getOneInscription(communityCode: string, id: number): Promise<InscriptionDTO> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getOneInscription/${id}`);
  }

  public getBonificationsInscription(communityCode: string, id: number): Promise<BonificationDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getBonificationsInscriptions/${id}`);
  }

  public getRecurrenceInscription(communityCode: string, id: number): Promise<RecurrenceDTO[]> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/getRecurrencesInscriptions/${id}`);
  }

  public sendInscription(communityCode: string, inscriptionSend: InscriptionSentDTO): Promise<void> {
    return this.post(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/SendInscriptionForm`, inscriptionSend);
  }

  public getMyInscriptions(communityCode: string, userId: number) {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/my-inscriptions/${userId}`)
  }

  public sendMailTowaitingList(communityCode: string, id: number, mail: string): Promise<void> {
    return this.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Inscriptions/waitingList/${id}/${mail}`);
  }

  //#endregion

  //#region DEVELOPER OPTIONS
  

  //#endregion

}
