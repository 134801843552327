import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, type OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFieldDTO } from 'src/app/models/CustomFieldDTO';
import { CustomFieldFormComponent } from '../custom-field-input/custom-field-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserModel } from 'src/app/models/UserModel';

@Component({
    selector: 'app-custom-fields-form',
    templateUrl: './custom-fields-form.component.html',
    styleUrls: ['./custom-fields-form.component.scss'],    
    standalone: true,
    imports: [
        CommonModule,
        CustomFieldFormComponent,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class CustomFieldsFormComponent implements OnInit {

    @Input() customFields: CustomFieldDTO[];
    @Input() fieldForm: FormGroup;
    @Input() formBuilder: FormBuilder
    @Input() submitAttempted: boolean;
    @Input() isLogged: boolean;
    @Input() user: UserModel;

    @Output() priceChanged: any = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.customFields.length !== 0) {
            for (const customField of this.customFields) {
                let value = null;
                if (this.isLogged) {
                    value = this.getUserField(customField.key, this.user);
                }
                this.fieldForm.addControl(customField.id.toString(), this.formBuilder.control(value));
            }
        }
    }

    private getUserField(key: string, user: UserModel) {
        switch (key) {
            case 'NAME':
                return user.name;
            case 'SURNAME':
                return user.surname;
            case 'MAIL':
                return user.mail;
            default:
                return null;
        }
    }
}
