import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageMode'
})
export class ImageModePipe implements PipeTransform {

  transform(value: string, mode: string): any {
    
    if (!value || value.includes('avatars')) {
      return value;
    }
    
    const rest = value.substring(0, value.lastIndexOf('/') + 1);
    const last = value.substring(value.lastIndexOf('/'), value.length);

    return rest + mode + last;
  }

}
