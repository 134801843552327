import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, MenuController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {

  @Input() refresh: EventEmitter<any>;

  @Input() segmentChanges: BehaviorSubject<boolean>;

  public firstLoad: BehaviorSubject<boolean>;

  public segmentValue = 'new'; 

  public isLogged = false;

  constructor(
    public router: Router,
    public auth: AuthService,
    private menu: MenuController
  ) {
    this.segmentChanges = new BehaviorSubject<boolean>(true);
    this.firstLoad = new BehaviorSubject<boolean>(true);
    this.segmentChanges.subscribe((res) => {
      this.segmentValue = res ? 'new' : 'readed';
    })
   }

  ngOnInit() {
    this.refresh.subscribe(() => {
      this.segmentValue = 'new';
      this.firstLoad.next(true);
    });
    this.auth.isAuthenticatedObs().subscribe((res) => {
      this.isLogged = res;
    });
  }

  segmentChanged(ev: any) {
    this.segmentChanges.next(this.segmentValue === 'new');
  }

  openConfig(){
    this.menu.close();
    this.router.navigate(['/user/notifications']);
  }

}
