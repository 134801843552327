import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-translate-button',
    templateUrl: './translate-button.component.html',
    styleUrls: ['./translate-button.component.scss'],
})
export class TranslateButtonComponent implements OnInit {

    @Input() smaller: boolean = false;
    @Input() lang: string;
    @Input() textToTranslate: {};

    @Output() getTranslations = new EventEmitter<{}>(null);        
    
    public showTranslate: boolean;
    public translated: boolean;
    public appLang: string;

    private translations = {};
    
    constructor(private languageService: LanguageService) {}

    ngOnInit(): void {         

        this.appLang = this.languageService.getLanguage();
        
        if (this.lang) {
            this.showTranslate = this.lang != this.appLang;
            console.log(this.showTranslate)
        }           
    }
    
    public translateModel() {
        if (!this.translated) {
            if (this.checkDictEmpty(this.translations)) {
                this.getGoogleTranslation()
                    .then((result: any) => {
                        this.translations = this.setDictTranslations(result)
                        this.translated = !this.translated;
                        this.getTranslations.emit(this.translations)
                    })
                    .catch((err) => {
                        this.getTranslations.emit(null);
                        console.warn('TRANSLATION ERROR: ', err);
                    })
            } else {
                this.translated = !this.translated;
                this.getTranslations.emit(this.translations);
            }
        }
        else {
            this.translated = !this.translated;
            this.getTranslations.emit(this.textToTranslate);
        }
    }

    private getGoogleTranslation():Promise<string[]> {
        const translations: any[] = Object.values(this.textToTranslate)
            .map(t => (t == null || t == undefined ? '' : t));
        
        return new Promise (async (resolve, reject) => {
            try {
                this.languageService.googleTranslate(translations, 'text')
                    .subscribe((result: any) => {
                        const translation = result.data.translations;                        
                        resolve(translation);
                })
            } catch (err) {
                reject(err);
            }
        })
    }
    
    private setDictTranslations(values: any[]) {
        let index = 0;
        let dict: {} = {};
    
        for(var key in this.textToTranslate) {              
            dict[key] = values[index].translatedText;
            index++;  
        }
    
        return dict;
    }
    
    private checkDictEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
}
