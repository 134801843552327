import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConsultationDTO } from 'src/app/models/ConsultationDTO';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
import { ConsultationStatus } from 'src/constants';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as prettyBytes from 'pretty-bytes';
import { ConsultationAnswerDTO } from 'src/app/models/ConsultationAnswerDTO';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  @Input() id: number;
  @ViewChild('pieChart') pieChart;

  model: ConsultationDTO;

  active = false;
  selectable = true;
  response?: number;

  results: any;
  answersResults: Array<ConsultationAnswerDTO> = [];

  chart: any;

  prettyBytes = prettyBytes;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private nav: NavController,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private events: EventsService
  ) {
    
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = parseInt(id);
    }

    this.api.getConsultationById(this.id).then(res => {
      this.model = res;
      this.active = this.model.statusId === ConsultationStatus.ACTIVE;
      this.selectable = this.active;

      this.model.endDate_m = moment(this.model.endDate);
      this.model.active = this.model.endDate_m.isAfter();
      this.model.RemainingTime = moment().to(this.model.endDate);

      if (res.votedByMe){
        this.response = res.myVote;
      }

      if (!this.active || res.votedByMe){
        this.selectable = false;
        this.getResults();
      }

    });

  }

  async back() {
    const top = await this.modalCtrl.getTop();
    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }

  select(val: number) {
    this.response = val;
  }

  changeVote(){
    this.selectable = true;
  }

  async send(){
    let isAuth = await this.auth.isAuthenticated();
    if (!isAuth){
      const t = await this.translate.get(['Oops', 'Vote.YOU_NEED_TO_BE_LOGGED', 'LOGIN', 'CANCEL']).toPromise();

      Swal.fire({
        title: t.Oops,
        text: t['Vote.YOU_NEED_TO_BE_LOGGED'],
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t.LOGIN,
        cancelButtonText: t.CANCEL,
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
         this.router.navigate(['/login']);
        }
      });
      return;
    }


    const t = await this.translate.get(['SURE?', 'Vote.SEND_ANSWER_CONFIRMATION', 'CONTINUE', 'CANCEL']).toPromise();

    Swal.fire({
      title: t['SURE?'],
      text: t['Vote.SEND_ANSWER_CONFIRMATION'],
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t.CONTINUE,
      cancelButtonText: t.CANCEL,
      reverseButtons: true
    })
    .then((result) => {
      if (result.value){
        this.api.voteConsultation(this.model.id, this.response)
        .then(() => {
          //Swal.fire('Bé!', 'La teva resposta s\'ha enviat', 'success');
          this.model.votedByMe = true;
          this.getResults();
          this.selectable = false;
       });
      }
    });

    

    //this.createChart();
    //this.sent = true;
  }

  getResults(){
    this.api.getConsultationResults(this.model.id).then((res) => {
      this.results = res;
      this.calculatePercents();
     });
  }

  calculatePercents(){
    if (this.results) {

      this.results.results.forEach(el => {        
        let answerObj: ConsultationAnswerDTO = {
          percent: 0,
          style: null,
        };
        if (this.results.totalVotes === 0){          
          answerObj.style = this.domSanitizer.bypassSecurityTrustStyle(`clip-path: xywh(0 0 0% 100% round 8px 8px);`);                   
        }else {
          answerObj.percent = Math.round((el.votes / this.results.totalVotes) * 100);          
          answerObj.style = this.domSanitizer.bypassSecurityTrustStyle(`clip-path: xywh(0 0 ${answerObj.percent}% 100% round 8px 8px);`);      
        }        
        this.answersResults[el.answer.id] = answerObj;      
      });

      /*var answerResult = (this.results.results as Array<any>).find(x=> x.answer.id = id);
      console.log(answerResult);
      if(answerResult){
        return `calc(${answerResult.votes/this.results.totalVotes*100}% - 20px)`;
      }*/
    }
  }

  openUrl(url: string) {
    this.events.openUrl(url);
  }
  
}
