import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {DateTime} from "luxon";
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {CustomFieldDTO} from "../../models/CustomFieldDTO";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {IonicModule} from "@ionic/angular";
import { CustomFieldsConstants } from 'src/constants';

@Component({
  selector: 'app-custom-field-form',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldFormComponent),
      multi: true
    }
  ],
  templateUrl: './custom-field-form.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IonicModule,
  ],
  styleUrls: ['./custom-field-form.component.scss'],
})
export class CustomFieldFormComponent implements OnInit, ControlValueAccessor{

  public internalValue: any;

  public show = false;
  public oldValue: string;
  @Input()
  public customField: CustomFieldDTO;

  @Output() changeThePrice = new EventEmitter<any>();
  onChange: (_: string) => void = (_: string) => { };

  constructor() {

  }

  ngOnInit(): void {

  }

  updateChanges(value: any) {
    this.customField.value = this.modelToString(value);
    this.onChange(this.customField.value);
  }


  private modelToString(value: any){
    
    if (!value) { return null; }

    switch (this.customField.type) {
      case CustomFieldsConstants.InputTypes.DATE:
      case CustomFieldsConstants.InputTypes.DATETIME:        
      case CustomFieldsConstants.InputTypes.CHECKBOX:        
      case CustomFieldsConstants.InputTypes.NUMERIC:
        return value.toString();
      default:
        return value;
    }
  }
  private modelFromString(value: string): any{
    if (!value) { return null; }

    switch (this.customField.type) {
      case "DATE":
      case "DATETIME":
        return DateTime.fromISO(value).toJSDate();
      case "CHECKBOX":
        return value == 'true';
      default:
        return value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {

  }

  writeValue(val: string): void {
    if (this.customField) {
      this.customField.value = val;

      if (this.customField.dropdownOptions != '' && this.customField.dropdownOptions && this.customField.dropdownType === "CHIPS"){

        this.customField.dropdownOptionsArrayShow = this.customField.dropdownOptions.split('|')
      }
    }

    this.internalValue = this.modelFromString(val);
  }

  validate({ value }: FormControl) {
    const isNotValid = this.customField.value != '';
    return isNotValid && {
      invalid: true
    }
  }



}
