import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommunityModuleDTO } from 'src/app/models/CommunityModules/CommunityModuleDTO';
import { ApiService } from '../api.service';
import { CommunityService } from '../community/community.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityModulesService {

  private isLoaded = false;
  private isLoading = false;
  //private _modules: TownModuleDTO[];

  private _modules: BehaviorSubject<CommunityModuleDTO[]> = new BehaviorSubject(null);

  constructor(
    private api: ApiService,
    private communityService: CommunityService
  ) {
    
  }

  public get(townId: number = null, refresh = false): Observable<CommunityModuleDTO[]>{
    if((!this.isLoaded || refresh) && !this.isLoading){
      this.refresh(townId);
    }

    return this._modules.asObservable();
  }

  public getDict(townId: number = null, refresh = false): Observable<{}>{
    const observable = new Observable<{}>(subscriber => {
      this.get(townId, refresh).subscribe((res) => {
        if(res){
          let dict = {};
          res.forEach(el => {
            dict[el.moduleTypeKey] = el;
          });
          subscriber.next(dict);
        }
      });
    });

    return observable;
  }

  public getByKey(moduleKey: string, townId: number = null, refresh = false): Observable<CommunityModuleDTO>{
    return new Observable((sub) => {
      this.getDict(townId, refresh).subscribe((res) => {
        sub.next(res[moduleKey]);
      });
    });
  }

  private refresh(townId: number = null){
    this.isLoading = true;
    let callback = () => {
      this.api.Town_GetModules(townId).then((res) => {
        this.isLoaded = true;
        this.isLoading = false;
        this._modules.next(res);
      });
    };

    if(!townId){
      this.communityService.Get().subscribe(res => {
       if(res){
        townId = res.id;
        callback();
       }
      });
    }else{
      callback();
    }
  }
  
}
