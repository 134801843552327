<ion-header no-border class="corp">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'Notifications.Detail.TITLE' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="pulse-spinner" *ngIf="isLoading"></div>

  <div *ngIf="notif">

    <div *ngIf="logoAj" class="aj-logo" [style.backgroundImage]="'url(' + (logoAj | imageUrl:'towns/thumb') + ')'"></div>

    <div class="card">      
      <div *ngIf="notif.categoryId">
        <ion-badge class="ion-float-right" [style.--background]="notif.categoryColor">{{notif.categoryName}}</ion-badge>
      </div>

      <app-translate-button         
        [smaller]="true"
        [lang]="notif.lang"
        [textToTranslate]="translations"
        (getTranslations)="getTranslation($event)">
      </app-translate-button>
      
      <h2>{{notif.preTitle}}</h2>
      <h1>{{notif.bigTitle || notif.pushTitle}}</h1>
      <h3>{{notif.postTitle}}</h3>
  
      <img class="main-image" *ngIf="notif.image" [src]="notif.image | imageUrl:'notifications'" data-fancybox="gallery" loading="lazy"/>

      <div class="content" *ngIf="contentIsLoading">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>

      <div class="content" *ngIf="notif.proposalId">

        <div *ngIf="proposal" class="image-card" (click)="goToProposal(notif.proposalId)"> 
          <img [lazyLoad]="proposal.image | imageUrl:'proposals'" />
          <div class="banner">
            <div class="title">{{proposal.title}}</div>
          </div>
        </div>
        
        <ion-button *ngIf="proposal" expand="block" shape="round" (click)="goToProposal(notif.proposalId)">
          <span *ngIf="proposal.isSuggestion">{{'Notifications.Detail.SEE_PROPOSAL' | translate}}</span>
          <span *ngIf="!proposal.isSuggestion">{{'Notifications.Detail.SEE_PROPOSAL' | translate}}</span>
        </ion-button>
  
      </div>
  
      <div class="content" *ngIf="notif.consultationId">
        
        <div *ngIf="consultation" class="image-card" (click)="goToConsultation(notif.consultationId)">
          <img [lazyLoad]="consultation.image | imageUrl:'consultations'" />
          <div class="banner">
            <div class="title">{{consultation.title}}</div>
          </div>
        </div>

        <ion-button *ngIf="notif.consultationId" expand="block" shape="round" (click)="goToConsultation(notif.consultationId)">
          {{'Notifications.Detail.SEE_CONSULTATION' | translate}}
        </ion-button>
  
      </div>
  
      <div class="content" *ngIf="notif.alertId">
        
        <div *ngIf="alert" class="image-card" (click)="goToIncident(notif.alertId)">
          <img [lazyLoad]="alert && alert.images.length? (alert.images[0] | imageUrl:'incidents') : ''" />
          <div class="banner">
            <div class="title">{{alert.name}}</div>
          </div>
        </div>

        <ion-button *ngIf="notif.alertId" expand="block" shape="round" (click)="goToIncident(notif.alertId)">
          {{'Notifications.Detail.SEE_INCIDENT' | translate}}
        </ion-button>
  
      </div>
      
      <div class="body" [class.html]="htmlDescription">
      
        <span *ngIf="notif.body"[innerHTML]="bodyDescription"></span>
        
        <span *ngIf="notif.adminCustomMessage">{{notif.adminCustomMessage}}</span>

        <span *ngIf="!notif.body && !notif.adminCustomMessage">{{notif.pushMessage}}</span>

        <div class="attached-imgs" *ngIf="notif.images && notif.images.length > 0">
          <span class="attached-msg">{{ 'Notifications.Detail.AttachedImages' | translate }}</span>
          <div class="images-container">
            <img 
              *ngFor="let img of notif.images" 
              class="rounded-img"
              [src]="img | imageUrl" 
              alt=""
              [attr.data-fancybox]="'gallery' + img" loading="lazy">
          </div>
        </div>
      </div>

      <div class="events-list" *ngIf="notif.type == 50">
        <app-event-tile *ngFor="let event of eventList" [event]="event" (click)="goToEvent(event.id)"></app-event-tile>
      </div>

      <div class="events-list" *ngIf="notif.type == 10">
        <app-element-tile *ngFor="let n of featuredNews" [new]="n" (click)="goToNew(n.id)"></app-element-tile>
      </div>      

      <ng-container *ngIf="notif.files.length">
        <h4>{{ 'ATTACHED_FILES' | translate }}</h4>
        <app-attached-files [files]="notif.files"></app-attached-files>
      </ng-container>      

      <app-feedback-faces [type]="reactionType" [id]="id"></app-feedback-faces>      
    </div>    
  </div>
</ion-content>
