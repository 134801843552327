<div [formGroup]="fieldForm" class="mb-3" *ngFor="let field of customFields; let i = index;">
    <app-custom-field-input *ngIf="field" [customField]="field" (changeThePrice)="priceChanged.emit($event)"
        formControlName="{{field.id.toString()}}"
        [class.error]="fieldForm.get(field.id.toString())?.invalid && submitAttempted">
    </app-custom-field-input>
    <div class="mb-3 mt-1 text-danger">
        <div *ngIf="fieldForm.get(field.id.toString())?.hasError('required') && submitAttempted">
            {{ 'RequiredField' | translate }}
        </div>

        <div *ngIf="fieldForm.get(field.id.toString())?.hasError('format') && submitAttempted">
            {{ 'WRONG_FORMAT' | translate }}
        </div>
    </div>
</div>