<div class="translate-box" *ngIf="showTranslate">
    <ion-button [class.smaller]="smaller" [size]="smaller ? 'small' : 'medium'" color="medium" fill="outline" shape="round" class="btn-translate" type="button"
        (click)="translateModel()">
        <ion-icon name="language-outline"></ion-icon>
        &nbsp;
        <span>
            {{ translated ? ('TRANSLATE.Original' | translate) : ('Translate' | translate) }}
        </span>
    </ion-button>
    <div class="translate-warning" *ngIf="translated">
        <span>{{ 'TRANSLATE.AdviceMessage' | translate }}</span>
    </div>
</div>