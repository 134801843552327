import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NotificationsPage } from './notifications.page';
import { DetailPageModule } from './detail/detail.module';

import { TranslateModule } from '@ngx-translate/core';
import { ListComponentModule } from './list/list.module';

const routes: Routes = [
  {
    path: '',
    component: NotificationsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    DetailPageModule,
    TranslateModule,
    ListComponentModule
  ],
  declarations: [NotificationsPage],
  exports: [
    NotificationsPage
  ]
})
export class NotificationsPageModule {}
