<!--<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{'Proposals.Detail.TITLE' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>-->

<ion-content fullscreen>
  <div class="float-buttons left top">
    <a class='button' (click)="backStep()">
      <i class="fas fa-chevron-left"></i>
    </a>
  </div>

  <div *ngIf="!proposal" class="pulse-spinner"></div>

  <app-proposal-detail *ngIf="proposal" [proposal]="proposal"></app-proposal-detail>
</ion-content>
