import { ApiService } from 'src/app/services/api.service';
import { LocationService } from './../../services/location.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { IonSearchbar, Platform } from '@ionic/angular';
import { CityPolygonModel } from 'src/app/models/Maps/CityPolygonModel';
import { CircleMarker, DragEndEvent, icon, latLng, LatLng, LocationEvent, 
  Map, MapOptions, Marker, marker, point, Polygon, tileLayer, LeafletEvent, circle } from 'leaflet';
import { MapboxService } from 'src/app/services/mapbox.service';
import { MAPBOX_TOKEN } from 'src/environments/environment';
import { Keyboard } from '@capacitor/keyboard';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit, AfterViewInit {

  @Input('marker-circle-radius') MarkerCircleRadius: number;

  @Output() onCoordsChange: EventEmitter<LatLng> = new EventEmitter<LatLng>();
  @Output() onAddressChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onMarkerInCityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addressInput') addressInput: IonSearchbar;

  map: Map;
  mapOptions: MapOptions;
  mapLoading = true;

  city: CityPolygonModel;

  address: string;

  locationMarker: Marker;
  locationMarkerCircle: CircleMarker;

  searchStreetTimeout: any;
  searchStreetDisabled: boolean;

  loadingAddress: boolean;
  
  dragIdleTimeout: any;
  coordsDragged = false;
  coordsTouched = false;
  public dragHelper = false;

  isFirstLocating = true;
  isLocating = false;

  constructor(
    public ref: ChangeDetectorRef,
    private storage: StorageService,
    private mapbox: MapboxService,
    private translate: TranslateService,
    private location: LocationService,
    private api: ApiService,
    private platform: Platform
  ) { }

  ngOnInit() {
    this.isLocating = false;
    this.isFirstLocating = true;
  }

  ngAfterViewInit() {
    this.storage.GetTown().then((res) => {

      this.api.Town_GetGeoTown(res.id).then((res: any) => {
        
        if(res){
          this.city = new CityPolygonModel(res.geojson);
        }

        //this.storage.get('cityPolygon')
        this.initializeMapOptions();
        
        setTimeout(() => {
          this.mapLoading = false;
        }, 0);
      });
    });
  }

  private initializeMapOptions() {
    this.mapOptions = {
      attributionControl: false,
      center: this.city ? this.city.getCenter() : new LatLng(40.0619708, -2.1655345),
      zoom: this.city ? null : 4,
      dragging: true,
      zoomControl: false,
      layers: [
        tileLayer(
          `https://api.mapbox.com/styles/v1/dfargas/ckle1xk064nir17nu31n2dd0c/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
          {
            maxZoom: 20,
            tileSize: 512,
            zoomOffset: -1
          }),
      ],
    };
  }

  onMapReady(map: Map) {
    this.map = map;
    //map.addControl(control.zoom({ position: 'bottomright' }));

    if(this.city){
      this.map.fitBounds(this.city.getBounds(), 
      {
        padding: point(-20, -20)
      });

      // Construct the polygon.
      const polygon = this.city.getPolygonInverse();
    
      this.map.addLayer(polygon);
    }

    this.map.addEventListener('moveend', () => {
      console.log('moveend');
      //this.blurInput();
    });

    this.map.addEventListener('dragstart', () => {
      console.log('dragstart blurInput');
      this.blurInput();
      
      this.stopIdleTimeout();
    });

    this.map.addEventListener('dragend', () => {
      //this.startIdleTimeout();
      this.coordsTouched = true;
      this.stopIdleTimeout();
    });

    this.map.addEventListener('click', (e: LocationEvent) => {
      this.placeMarkerAndPanTo(e.latlng);
      this.coordsTouched = true;

      this.stopIdleTimeout();
      this.startIdleTimeout();
    });

    /*
    this.location.getCurrentPosition().then((res)=>{
      let pos = new google.maps.LatLng(res.latitude, res.longitude);

      this.map.setCenter(pos);

      this.locationMarker = new google.maps.Marker({
        map: this.map,
        position: pos,
        draggable:true,
        animation: google.maps.Animation.DROP,
      });

      this.coords = {
        lat: res.latitude,
        lng: res.longitude,
      }
    });
    */

    this.GetGPS();
  }

  checkMarkerInsideCity(LatLng: LatLng){
    if(this.city){
      const isMarkerInCity = this.isPointInsidePolygon(LatLng, this.city.getPolygon());
      this.onMarkerInCityChange.emit(isMarkerInCity);
      return isMarkerInCity;
    } else {
      this.onMarkerInCityChange.emit(true);
      return true;
    }
  }

  isPointInsidePolygon(point: LatLng, poly: Polygon) {
    const polyPoints = (poly.getLatLngs() as LatLng[][])[0];       
    const x = point.lat, y = point.lng;

    let inside = false;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat, yi = polyPoints[i].lng;
      const xj = polyPoints[j].lat, yj = polyPoints[j].lng;

      const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
}

  blurInput(){
    //let activeElement = <HTMLElement>document.activeElement;
    //activeElement && activeElement.blur && activeElement.blur();
    if (this.platform.is('capacitor')) {
      Keyboard.hide();
    }
    
  }

  onAddressInputChange(){

    if (!this.searchStreetDisabled){
      this.onCoordsChange.emit(null);
    }

    if (this.searchStreetTimeout){
      clearTimeout(this.searchStreetTimeout);
    }

    this.searchStreetTimeout = setTimeout(() => {
      if (this.searchStreetDisabled){
        this.searchStreetDisabled = false;
      }else{
        this.SearchAddress();
      }
    }, 1500);
  }

  onAddressEnter(){
    
    this.onCoordsChange.emit(null);

    if (this.searchStreetTimeout){
      clearTimeout(this.searchStreetTimeout);
    }

    this.SearchAddress();
  }

  SearchAddress() {
    if (!this.address) {
      this.onCoordsChange.emit(null);
      this.onAddressChange.emit(this.address);
      return;
    }

    this.loadingAddress = true;

    this.mapbox.Geocode(this.address, this.city?.getBounds())
    .then((data: any) => {
      console.log(data);
      this.loadingAddress = false;

      if (data.features.length) {
        console.log('Geocode blurInput');
        this.blurInput();

        const c = data.features[0].center;
        this.placeMarkerAndPanTo(latLng(c[1], c[0]));

        this.startIdleTimeout();

      } else {
        console.error('Geocode was not successful:', data);
      }
    });
  }

  placeMarkerAndPanTo(location: LatLng) {
    
    this.map.flyTo(location, 16, { duration: 1 });

    this.removeMarker();

    this.addMarker(location);

    if (this.MarkerCircleRadius){
      this.addMarkerCircle(location);
    }

    this.changeCoords(location);
  }

  addMarker(location: LatLng){
    this.locationMarker = marker(location, {
      draggable: true,
      riseOnHover: true,
      autoPan: true,
      autoPanPadding: [20, 20],
      icon: icon({
        iconSize: [ 38, 38 ],
        iconAnchor: [ 19, 38],
        iconUrl: 'assets/icons/markers/marker_move.svg',
        shadowUrl: 'assets/icons/markers/marker_shadow.png',
        shadowAnchor: [13, 40],
        //className: 'animated-icon' 
      })
    });

    this.locationMarker.addEventListener('dragstart', (e: LeafletEvent) => {
      this.coordsDragged = true;
      this.coordsTouched = true;
      this.stopIdleTimeout();
    });

    this.locationMarker.addEventListener('drag', (e: LeafletEvent) => {
      if (this.locationMarkerCircle){
        this.locationMarkerCircle.setLatLng(this.locationMarker.getLatLng());
      }
    });

    this.locationMarker.addEventListener('dragend', (e: DragEndEvent) => {
      const pos = this.locationMarker.getLatLng();
      this.changeCoords(pos);
    });

    this.map.addLayer(this.locationMarker);
  }

  addMarkerCircle(location: LatLng){
    this.locationMarkerCircle = circle(location, {
      radius: this.MarkerCircleRadius,
      stroke: false
      });

    this.map.addLayer(this.locationMarkerCircle);
  }

  removeMarker(){
    if (this.locationMarker) {
      this.locationMarker.remove();
    }

    if (this.locationMarkerCircle){
      this.locationMarkerCircle.remove();
    }

    this.stopIdleTimeout();
  }
  
  changeCoords(location: LatLng){
    const coords = location;
    this.onCoordsChange.emit(coords);
    this.checkMarkerInsideCity(location);
  }
  
  startIdleTimeout(){
    this.dragIdleTimeout = setTimeout(() => {
      this.dragHelper = true;
      this.ref.detectChanges();
    }, 2000);
  }

  stopIdleTimeout(){
    this.dragHelper = false;
    this.ref.detectChanges();

    if(this.dragIdleTimeout){
      clearTimeout(this.dragIdleTimeout);
    }
  }

  GetGPS() {
    setTimeout(() => {
      this.isLocating = true;
    }, 0);

    
    this.location.getLocation(!this.isFirstLocating).then((res) => {
      const latlng = new LatLng(res.coords.latitude, res.coords.longitude);

      if (this.checkMarkerInsideCity(latlng)){
        this.placeMarkerAndPanTo(latlng);
        this.startIdleTimeout();
      }else if (!this.isFirstLocating){
        this.removeMarker();
        this.translate.get('Geolocation.NOT_INSIDE_CITY').subscribe((t) => {
          this.presentAlert(t);
        });
      }
    }).finally(() => {

      setTimeout(() => {
        this.isLocating = false;
        this.isFirstLocating = false;
      }, 0);

    });
  }


  async presentAlert(result: string) {
    this.translate.get(['Oops', 'ACCEPT'])
    .subscribe(t => {
      Swal.fire({
        title: t.Oops,
        text: result,
        icon: 'warning',
        confirmButtonText: t.ACCEPT,
      });
    });

  }

}
