<ul class="tiles-list-wrapper">
    <li *ngFor="let f of files" (click)="openFile(f)" class="ion-activatable">
      <i class="fas fa-paperclip main-icon"></i>
      <div class="file-info">
        <div class="name">{{ f.originalName }}</div>
        <div class="size" *ngIf="f.size">{{ prettyBytes(f.size) }}</div>
      </div>
      <i class="fas fa-arrow-down right-icon" *ngIf="!f.isLoading && !f.inDisk"></i>
      <i class="fas fa-eye right-icon" *ngIf="!f.isLoading && f.inDisk"></i>

      <ion-spinner name="crescent" *ngIf="f.isLoading"></ion-spinner>
      <ion-ripple-effect></ion-ripple-effect>
    </li>
</ul>