import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePopupComponent } from './image-popup.component';

@NgModule({
  declarations: [
    ImagePopupComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
],
  exports: [
    ImagePopupComponent
  ]
})
export class ImagePopupComponentModule {}