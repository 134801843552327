import { Component, Input, type OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';

@Component({
    selector: 'app-external-link-list',    
    templateUrl: './external-link-list.component.html',
    styleUrls: ['./external-link-list.component.scss'],
})
export class ExternalLinkListComponent implements OnInit {

    @Input() public links: any[];

    constructor(private eventsService: EventsService) { }

    ngOnInit(): void { }

    public openUrl(url: string) {
        if (url.startsWith('http')) {
            this.eventsService.openUrl(url);
        } else {
            this.eventsService.openUrl('http://' + url);
        }
    }
}
