import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    if (value) {
      const phoneNumber = parsePhoneNumber(value, 'ES')
      if (phoneNumber) {
        //this.phoneUri = phoneNumber.getURI();
        return phoneNumber.formatNational();
      }
    }
    
    return '';
  }

}