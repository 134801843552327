import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { ElementTileComponent } from './element-tile.component';

@NgModule({
  declarations: [
    ElementTileComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    ApplicationPipesModule
],
  exports: [
    ElementTileComponent
  ]
})
export class ElementTileComponentModule {}