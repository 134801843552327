import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { DetailComponent } from './detail.component';
import { MapComponentModule } from '../../map/map.module';
import { CommentsComponentModule } from '../../comments/comments.module';

@NgModule({
  declarations: [
    DetailComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MapComponentModule,
    CommentsComponentModule,
    TranslateModule,
    LazyLoadImageModule,
    ApplicationPipesModule
],
  exports: [
    DetailComponent
  ]
})
export class IncidentDetailComponentModule {}