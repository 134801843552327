import { RouterModule } from '@angular/router';
import { EventTileComponent } from './event-tile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

@NgModule({
  declarations: [
    EventTileComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    ApplicationPipesModule
],
  exports: [
    EventTileComponent
  ]
})
export class EventTileComponentModule {}