import { HttpClient } from "@angular/common/http";
import { CommunityService } from "../services/community/community.service";
import { MultiTranslateHttpLoader, TranslationResource } from "./MultiTranslateHttpLoader";

export function HttpLoaderFactory(http: HttpClient, communityService: CommunityService) {

  // default translations
  const translationResouceList: TranslationResource[] = [
    { prefix: "./assets/i18n/", suffix: ".json" }
  ];
  
  return new MultiTranslateHttpLoader(http, translationResouceList, communityService);
}