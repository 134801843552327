import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProposalModel } from 'src/app/models/ProposalModel';
import { ModalController, NavController } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-proposal-detail-page',
  templateUrl: './proposal-detail.page.html',
  styleUrls: ['./proposal-detail.page.scss'],
})
export class ProposalDetailPage implements OnInit {

  @Input() id: number;
  proposal: ProposalModel;

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private nav: NavController,
    private location: Location,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = parseInt(id);
    }
    
    this.api.getProposal(this.id).then((res) => {
      this.proposal = res;
      this.isLoading = false;
    });
  }

  async backStep() {
    const top = await this.modalCtrl.getTop();
    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }

}
