<ng-container *ngIf="customField">
<div>
  <ion-item class="ion-no-padding " lines="none">
    <ion-label position="stacked" class="ion-padding-bottom">{{customField.name }} <span *ngIf="customField.required" class="required_asterisk">*</span></ion-label>

    <ng-container *ngIf="customField.type == 'TEXT' && customField.publicPermissions == 'rw'">
      <ion-input type="text" class="ion-padding" [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"/>
    </ng-container>

    <ng-container *ngIf="customField.type == 'CHECKBOX' && customField.publicPermissions == 'rw'">
    <ion-toggle
                     [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"></ion-toggle>
    </ng-container>

    <ng-container *ngIf="customField.type == 'TEXTAREA' && customField.publicPermissions == 'rw'">
      <ion-textarea  [ngModel]="internalValue" (ngModelChange)="updateChanges($event)" ></ion-textarea>
    </ng-container>
    <ng-container *ngIf="customField.type == 'NUMERIC' && customField.publicPermissions == 'rw'">

      <ion-input type="number"
                 class="w-100"
                 id="enrollmentFee"
                 name="enrollmentFee"
                 [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"
      > </ion-input>
    </ng-container>

    <ng-container *ngIf="customField.type == 'DATE' && customField.publicPermissions == 'rw'">
      <ion-datetime-button datetime="datetime" ></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
                  id="datetime"
                  presentation="date"

                  [(ngModel)]="internalValue" (ngModelChange)="updateChanges($event)"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </ng-container>

    <ng-container *ngIf="customField.type == 'DROPDOWN' && customField.publicPermissions == 'rw' && customField.dropdownType == 'CHIPS'">
      <ion-select 
        [placeholder]="'SelectOption' | translate"
        [(ngModel)]="internalValue" (ngModelChange)="updateChanges($event)">
        <ion-select-option  *ngFor="let val of customField.dropdownOptionsArrayShow" value="{{val}}">{{val}}</ion-select-option>
      </ion-select>
    </ng-container>
  </ion-item>
  <small [innerHTML]=" customField.description">

  </small>

</div>
</ng-container>
