export const CUSTOM_APPS_IDS = {
    "app.weco.gironahostaleria": "gironahostaleria",
    "app.wecoo.asetrans": "asetrans",
    "app.wecoo.asscampingsgirona": "ass-campings-girona",
    "app.wecoo.bcndigitalcongress": "bcn-digital-congress",
    "app.wetown.begur": "begur",
    "app.wetown.bellviselsarcs": "bellvis-els-arcs",
    "app.wetown.benasque": "benasque",
    "app.wetown.canetdemar": "canet-de-mar",
    "app.wetown.cassadelaselva": "cassa-de-la-selva",
    "app.wetown.cervera": "cervera",
    "app.wetown.elsauzal": "el-sauzal",
    "app.wetown.lamassana": "la-massana",
    "app.wetown.mirandadeebro": "miranda-de-ebro",
    "app.wetown.pedreguer": "pedreguer",
    "app.wetown.santacristinadaro": "santa-cristina-daro",
    "app.wetown.santaeulaliaderoncana": "santa-eulalia-de-roncana",
    "app.wetown.serinya": "serinya",
    "app.wetown.solsona": "solsona",
    "app.wetown.vilablareix": "vilablareix",
    "app.wetown.tonajove": "tona-jove"
};