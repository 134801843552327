import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthResponse } from './auth-response';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { LoginModel } from '../models/LoginModel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { API_URL } from 'src/environments/environment';
import { NotificationsCountDTO } from "../models/NotificationsCountDTO";
import { CommunityService } from "../services/community/community.service";
import { EventsService } from "../services/events.service";
import * as Sentry from "@sentry/angular-ivy";
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static TOKEN_KEY = 'TOKEN';
  private static REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
  
  private authStateBool: boolean;
  private authState = new ReplaySubject<boolean>(1);
  private authSubject: AuthResponse;

  private refreshTokenTimeout: any;

  private notificationCount = new ReplaySubject<NotificationsCountDTO>(1);
  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    private jwt: JwtHelperService,
    private _communityService: CommunityService,
    private events: EventsService,

    ) {
      this._communityService.Get().subscribe(() => {
        this.getUserNotificationsCount();
      });
      this.events.notificationsCountRefresh.subscribe(() => {
        this.getUserNotificationsCount();
      });
      this.plt.ready().then(() => {
        this.checkToken();
      });

    }

    async checkToken() {
      console.log('checkToken');
     
      const token = await this.storage.get(AuthService.TOKEN_KEY);
      const refreshToken = await this.storage.get(AuthService.REFRESH_TOKEN_KEY);

      if (token) {
        if (this.jwt.isTokenExpired(token)) {
          if (refreshToken) {
            this.refreshToken(refreshToken);
          }
        } else {
          this.http.get<any>(`${API_URL}/users/me`)
          .subscribe((user: any) => {
              this.changeAuthSubject({ 
                jwt: token, 
                user
              });
          });
        }
      } else {
        this.stopRefreshTokenTimer();
        this.changeAuthSubject(null);
      }
    }

    async login(model: LoginModel) {
      const res = await this.http.post<any>(`${API_URL}/users/authenticate`, model)
        .toPromise();
      this.changeAuthSubject(res);
    }

    async logout() {
      const token = await this.storage.get(AuthService.REFRESH_TOKEN_KEY);
      this.http.post<any>(`${API_URL}/users/revoke-token`, { token }).subscribe();

      this.stopRefreshTokenTimer();

      this.storage.remove(AuthService.TOKEN_KEY);
      this.storage.remove(AuthService.REFRESH_TOKEN_KEY);
      this.storage.set('REGISTER_POPUP', DateTime.now().toISO());

      this.changeAuthSubject(null);
    }

    async refreshToken(refreshToken?: string) {

      if (!refreshToken) {
        refreshToken = await this.storage.get(AuthService.REFRESH_TOKEN_KEY);
      }
      
      if(!refreshToken) {
        this.changeAuthSubject(null);
        return;
      }

      return this.http.post<any>(`${API_URL}/users/refresh-token`, { token: refreshToken })
          .toPromise()
          .then((res) => {
            this.changeAuthSubject(res);
          })
          .catch(() => this.changeAuthSubject(null));
    }

    isAuthenticatedObs(): Observable<boolean>{
      return this.authState.asObservable();
    }

    isAuthenticated(): Promise<boolean> {
      return this.authState.asObservable().pipe(first()).toPromise();
    }


    getNotificationNumbers(): Observable<NotificationsCountDTO>{
      return this.notificationCount.asObservable();
    }

    getUser() {
      return this.authSubject?.user;
    }

    private startRefreshTokenTimer() {
      this.stopRefreshTokenTimer();

      if(!this.authSubject) return;
      
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(this.authSubject.jwt.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      //console.log('Token Expires', expires);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
 
    private stopRefreshTokenTimer() {
      if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
    }

    private changeAuthSubject(res: AuthResponse){
      this.authSubject = res;

      if(res){
        this.storage.set(AuthService.TOKEN_KEY, res.jwt).then(() => {
          this.emitAuthState(true);
          this.startRefreshTokenTimer();
        });

        this.getUserNotificationsCount();

        if (res.refreshToken) {
          this.storage.set(AuthService.REFRESH_TOKEN_KEY, res.refreshToken);
        }

        Sentry.setUser({ username: res.user.username });

      } else {
        this.notificationCount.next({current: 0, others: 0});
        this.emitAuthState(false);
        Sentry.setUser(null);
      }
    }

    private emitAuthState(newState: boolean){
      if(this.authStateBool == null || this.authStateBool != newState){
        this.authState.next(newState);
      }
      this.authStateBool = newState;
    }

  private async getUserNotificationsCount() {
    const community = await this._communityService.Get().pipe(first()).toPromise();
    const isAuthenticated = await this.isAuthenticated();

    if (isAuthenticated && community){
      let count = await this.http.get<NotificationsCountDTO>(`${API_URL}/v2/Notifications/pendingCount/${community.id}`).pipe(first()).toPromise();
      this.notificationCount.next(count);
    } else {
      this.notificationCount.next({ current: 0, others: 0 });
    }
    
  }

}
