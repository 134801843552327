import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    constructor() { }

    transform(text: string, limit: number, trail: string = '...'): string {
        return text.length > limit ? text.substring(0, limit) + trail : text;
    }

}