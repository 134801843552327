<ion-content>
  <ion-refresher slot="fixed" mode="ios" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content
      pullingIcon="lines"
    ></ion-refresher-content>
  </ion-refresher>
  
  <div *ngIf="!isLoading && !list.length" class="no-data ion-text-center">
    <br><br>
    <ng-container *ngIf="isAuth && segmentChanges.value">
      {{'Notifications.NEW_EMPTY' | translate}}
    </ng-container>
    <ng-container *ngIf="isAuth &&  !segmentChanges.value">
      {{'Notifications.EMPTY' | translate}}
    </ng-container>

    <ng-container *ngIf="!isAuth">
      <div>{{'Notifications.LOG_IN' | translate}}</div>
      <p>
        <ion-button 
          fill="outline"
          shape="round"
          (click)="goToLogin()"
        >
          {{'LOG-IN' | translate}}
        </ion-button>
      </p>
    </ng-container>
  </div>

  <ion-list *ngIf="!isLoading && list.length">
    <ng-container *ngFor="let group of list">
      <ion-item-divider *ngIf="group.title">
        <ion-label>
          {{group.title}}
        </ion-label>
        <ion-button 
          *ngIf="segmentChanges.value && mark_readed_button && group == list[0]"
          color="dark"
          fill="outline"
          slot="end"
          class="ion-float-right"
          (click)="readAll()"
         >
         {{'Notifications.READED' | translate}}
        </ion-button>
      </ion-item-divider>
  
      <ion-item *ngFor="let n of group.list" (click)="open(n)" [class.not-read]="!n.readByMe" lines="none">
        
        <i [ngClass]="[iconNameByType(n.type), 'type-' + n.type]" size="large" slot="start"></i>
  
        <ion-label>
          <div>
            <ion-badge *ngIf="n.categoryId" class="ion-float-right" [style.--background]="n.categoryColor">{{n.categoryName}}</ion-badge>
          </div>
          <div class="title">{{n.title}}</div>
          <p>{{n.message}}</p>
          <time class="ion-float-right">{{n.createdAt | relativeTime }}</time>
        </ion-label>
      </ion-item>
    </ng-container>
    
  </ion-list>
  
  <div *ngIf="isLoading">  
    <ion-list>
      <ion-list-header>
        <ion-label>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </ion-label>
      </ion-list-header>
      <ion-item *ngFor="let i of [1,2,3,4,5,6]">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
  
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="{{ 'LOADING' | translate }}">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>