import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationDTO } from 'src/app/models/NotificationDTO';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
})
export class ImagePopupComponent implements OnInit {


  @Input() src: string;
  @Input() url: string;

  closed = false;
  show = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  onLoad() {
    this.show = true;
  }

  close() {
    this.closed = true;
  }

  open() {
    this.closed = true;
    this.router.navigate([this.url]);
  }

}
