<ion-content [scrollEvents]="true">
  <ng-container *ngIf="incident">

    <div class="header-content">

      <ion-slides 
        *ngIf="incident.images && incident.images.length" 
        [pager]="incident.images && incident.images.length > 1"
        class="photos">
        <ion-slide *ngFor="let img of incident.images">
          <div class="image" [lazyLoad]="img | imageUrl:'incidents'"></div>
        </ion-slide>
      </ion-slides>
  
      <div class="category-label" [style.backgroundColor]="incident.categoryBackgroundGradientFrom">
        <div class="icon" [lazyLoad]="incident.categoryImage | imageUrl"></div> <span>{{incident.categoryName}}</span>
      </div>

      <div class="float-buttons left bottom" >
      </div>

      <div class="float-buttons right bottom" *ngIf="!isPreview">
        
        <a class='button share' (click)="share()" [ngClass]="shareButtonAnimation">
          <i class="fas fa-share-alt"></i>
        </a>

      </div>

    </div>

    <div #main class="main-content">
     
      <div class="body ion-padding">

        <div class="ion-text-right" >
          <small class="color-incident-status status_{{incident.statusId}}">{{incident.statusName}}</small>
        </div>

        <h1>
          {{incident.name}}
        </h1>

        <div class="creator">
          <img [src]="incident.creator_Photo | imageUrl | imageMode:'thumb'" class="user-image"><span class="user-name">{{ incident.creator_Name}}</span>
          <time>{{incident.createdAt | date:'dd/MM/yy'}}</time>
        </div>

        <div class="status-card status_{{incident.statusId}}" *ngIf="incident.status_AdminMessage && (incident.statusId == 10 || incident.statusId == 15 || incident.statusId == 20)">
          <div class="header">
            <i class="far fa-thumbs-up" *ngIf="incident.statusId == 10"></i>
            <i class="far fa-times-circle" *ngIf="incident.statusId == 20"></i> 
            <i class="icon-stopwatch" *ngIf="incident.statusId == 15"></i> 
            {{'Incident' | translate}} {{incident.statusName | lowercase}}
          </div>
          <div class="text">
            {{incident.status_AdminMessage}}
          </div>
        </div>

        <div class="description">
          {{incident.description}}
        </div>
                  
        <div *ngIf="incident.locationLat">
          <h2>{{'LOCATION' | translate}}</h2>
          <div>{{locationString}}</div><br>
          <app-map [lat]="incident.locationLat" [lng]="incident.locationLng" [zoom]="16"></app-map>
        </div>

        <div *ngIf="statusHistory" class='line-container'>
          <div class='progress-line'>
            <div class='progress' [style.width.%]="statusPercent"></div>
            
            <div class='status' *ngFor="let s of statusHistory">
               <div class='dot' [class.completed]="s.completed" [class.current]="s.current"></div>
               <div class='text'>
                 <div class='name'>{{s.text}}</div>
                 <time>
                  {{s.when? s.when.format('L') : ""}}
                 </time>
                </div>
            </div>
          </div>
         
        </div>

      </div>
      
    </div>
  </ng-container>
</ion-content>
