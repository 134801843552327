import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { icon, latLng, MapOptions, marker, tileLayer, Map, control, Marker,  } from 'leaflet';
import { MAPBOX_TOKEN } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  @Input('lat') lat: number;
  @Input('lng') lng: number;
  @Input('zoom') zoom: number;

  map: Map;
  mapOptions: MapOptions;
  marker: Marker;

  constructor() { }

  ngOnInit() {
    this.mapOptions = {
      attributionControl: false,
      center: latLng(this.lat, this.lng),
      zoom: this.zoom,
      dragging: false,
      zoomControl: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      layers: [
        tileLayer(
          `https://api.mapbox.com/styles/v1/dfargas/ckle1xk064nir17nu31n2dd0c/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
          {
            maxZoom: 20,
            tileSize: 512,
            zoomOffset: -1
          }),
      ],
    };
  }

  ngAfterViewInit() {
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map) {
      const _latLng = latLng(this.lat, this.lng);
      if (this.marker) { this.marker.setLatLng(_latLng); }
      this.map.panTo(_latLng);
    }
  }

  onMapReady(map: Map) {
    this.map = map;

    setTimeout(() => {
      map.invalidateSize();
    }, 200);
    
    map.addControl(control.zoom({ position: 'bottomright' }));
    this.addMarker();
  }

  addMarker() {
    
    this.marker = marker([ this.lat, this.lng ], {
      icon: icon({
        iconSize: [ 27, 43 ],
        iconAnchor: [ 13.5, 43],
        iconUrl: 'assets/icons/markers/marker.svg',
        shadowUrl: 'assets/icons/markers/marker_shadow.png',
        shadowAnchor: [13.5, 47],
      })
    });

    this.map.addLayer(this.marker);
  }
}
