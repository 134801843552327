import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'domain',
})
export class DomainPipe implements PipeTransform {

  transform(value: string): unknown {
    const url = new URL(value);
    return url.hostname;
  }

}
