import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { DetailPage } from './detail.page';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MapComponentModule } from 'src/app/components/map/map.module';
import { TranslateModule } from '@ngx-translate/core';
import { TicketsBuySectionComponentModule } from '../../MODULES/ticketing/components/tickets-buy-section/tickets-buy-section.component.module';
import {AttachedFilesComponentModule} from "../../../components/attached-files/attached-files.module";
import { FeedbackFacesComponentModule } from 'src/app/components/feedback-faces/feedback-faces.module';
import { TranslateButtonModule } from 'src/app/components/translate-button/translate-button.module';
import 'add-to-calendar-button';
import { ExternalLinksListModule } from 'src/app/components/external-link-list/external-link-list.module';

const routes: Routes = [
  {
    path: '',
    component: DetailPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ApplicationPipesModule,
        LazyLoadImageModule,
        MapComponentModule,
        TranslateModule,
        TicketsBuySectionComponentModule,
        AttachedFilesComponentModule,
        FeedbackFacesComponentModule,
        TranslateButtonModule,
        ExternalLinksListModule
    ],
  declarations: [DetailPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DetailPageModule {}
