<form (ngSubmit)="onAddressEnter()">
  <ion-searchbar #textInput 
    [placeholder]="'SearchStreet' | translate" 
    [(ngModel)]="address" 
    [ngModelOptions]="{standalone: true}"
    (ionChange)="onAddressInputChange()"
  ></ion-searchbar>
</form>

<img [hidden]="!dragHelper" class="drag-helper" src="assets/img/finger.png">

<div class="map-container">
  <div class="top-buttons">
    <ion-button slot="icon-only" (click)="GetGPS()" [disabled]="isLocating"><i class="fas fa-location-arrow"></i></ion-button>
  </div>
  
  <div *ngIf="!mapLoading" id="map" [class.loading]="loadingAddress"
    leaflet
    [leafletOptions]="mapOptions"
    (leafletMapReady)="onMapReady($event)"
  ></div>
</div>