import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, LatLngBounds } from 'leaflet';
import { MAPBOX_TOKEN } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  constructor(
    private  http: HttpClient,
  ) { }


  Geocode(searchText: string, bbox: LatLngBounds){
    const bboxStr = (bbox) ? `&bbox=${bbox.toBBoxString()}` : '';
    return new Promise<any>((resolve, reject) => {
      this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?types=address${bboxStr}&access_token=${MAPBOX_TOKEN}`)
        .subscribe((data: any) => {
          console.log(['MAPBOX RESPONSE', data]);
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  reverseGeocode(c: LatLng) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${c.lng},${c.lat}.json?types=address,poi&access_token=${MAPBOX_TOKEN}`)
        .subscribe((data: any) => {
          console.log(['MAPBOX RESPONSE', data]);
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  reverseGeocode_GetStreetAndNumber(c: LatLng) {
    return new Promise<any>((resolve) => {
      this.reverseGeocode(c).then((data: any) => {
          //resolve(data.features[0].place_name);

          if(!data.features.length){
            resolve(null);
            return;
          }

          const address = data.features[0].address;
          if (address && typeof address !== 'undefined' && address !== 'undefined') {
            resolve(data.features[0].text + ' ' + address);
          } else {
            resolve(data.features[0].text);
          }
        });
    });
  }
}
