<ion-content [scrollEvents]="true">

  <ng-container *ngIf="isPreview">
    <div class="preview-bubble-wrapper">
      <div class="preview-bubble ion-padding ion-text-center">
        <h2><ion-icon name="eye-outline"></ion-icon> <span>{{'PREVIEW' | translate}}</span></h2>
        <div class="subtitle" *ngIf="dynamicName == 'PROPOSAL'">{{'Proposals.Create.PREVIEW_TITLE' | translate}}</div>
        <div class="subtitle" *ngIf="dynamicName == 'SUGGESTION'">{{'Suggestion.Create.PREVIEW_TITLE' | translate}}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="x">

    <div class="header-content">

      <ion-slides 
        *ngIf="x.images && x.images.length" 
        [pager]="x.images && x.images.length > 1" 
        [options]="imagesOpts"
        class="photos">
        <ion-slide *ngFor="let img of x.images">
          <div class="image" [lazyLoad]="img | imageUrl:'proposals'"></div>
        </ion-slide>
      </ion-slides>
  
      <div class="category-label" [style.backgroundColor]="x.categoryBackgroundGradientFrom">
        <div class="icon" [lazyLoad]="x.categoryImage | imageUrl"></div> <span>{{x.categoryName}}</span>
      </div>

      <div class="float-buttons left bottom" *ngIf="!isPreview && !x.isSuggestion">

        <a class='button info' (click)="ShowInfo()" [ngClass]="shareButtonAnimation">
          <i class="fas fa-question"></i>
        </a>

      </div>

      <div class="float-buttons right bottom" *ngIf="!isPreview && !x.isSuggestion">

        <a class='button like' [class.liked]="x.followedByMe" [ngClass]="likeButtonAnimation"  (click)="follow()">
          <span class='icon'>
            <div class='heart-animation-1'></div>
            <div class='heart-animation-2'></div>
          </span>
        </a>

        <a class='button share' (click)="share()" [ngClass]="shareButtonAnimation">
          <i class="fas fa-share-alt"></i>
        </a>

      </div>

    </div>

    <div #main class="main-content">

      <ng-container *ngIf="!x.isSuggestion">
        <div class="tooltip-wrapper" 
          [hidden]="isPreview || !tooltipEnabled" 
          [style.opacity]="tooltipOpacity"
          >
          <span #tooltip 
            class="tooltiptext animate__animated"
            [class.animate__bounceIn]="tooltipAnimation == 'in'"
            [class.animate__fadeOut]="tooltipAnimation == 'out'"
            [attr.style]="sanitizer.bypassSecurityTrustStyle('--angleLeft: ' + angleLeft + 'px; --tooltipLeft: ' + tooltipLeft + 'px')">
            {{ 'VOTES_REMAINING' | translate }} <strong>{{x.votes_Objective - x.votes_Balance }}</strong> {{((x.votes_Objective - x.votes_Balance) == 1? 'VOTES_ONE' : 'VOTES') | translate}}!
          </span>
        </div>
     
        <div class="progress"
          [style.opacity]="progressBarPercent ? 1 : 0"
          [class.animated] = "x.votes_Balance > 0"
          [style.width.%]="progressBarPercent * 100"
          [style.backgroundColor]="x.categoryBackgroundGradientFrom">
          <!--<span>{{x.votes_Balance}} {{x.votes_Balance == 1? 'vot' : 'vots'}}</span>-->
          <span #progressPill 
          [style.backgroundColor]="x.categoryBackgroundGradientFrom"
          (click)="showTooltip()"
          >
            {{x.votes_Balance}} <i class="fas fa-users"></i>
          </span>
        </div>
      </ng-container>

      <div class="body ion-padding">

        <div *ngIf="expiresTime" class="ion-text-right" >
          <small>{{'EXPIRES_IN' | translate}} {{expiresTime}}</small>
        </div>
        <div *ngIf="!expiresTime" class="ion-text-right" >
          <small class="color-proposal-status status_{{x.statusId}}">{{dynamicName == 'SUGGESTION' && x.statusNameAlt ? x.statusNameAlt : x.statusName}}</small>
        </div>

        <h1>
          {{x.title}}
        </h1>

        <div class="creator">
          <img [src]="x.creator_Image | imageUrl | imageMode:'thumb'" class="user-image"><span class="user-name">{{ x.creator_Name}}</span>
          <time>{{x.createdAt | date:'shortDate'}}</time>
        </div>


        <div class="status-card status_{{x.statusId}}" *ngIf="x.status_AdminMessage && (x.statusId == 40 || x.statusId == 45 || x.statusId == 50)">
          <div class="header">
            <i class="far fa-thumbs-up" *ngIf="x.statusId == 40"></i>
            <i class="far fa-times-circle" *ngIf="x.statusId == 45"></i> 
            <i class="icon-stopwatch" *ngIf="x.statusId == 50"></i> 
            <span>{{(dynamicName == 'SUGGESTION' ? 'SUGGESTION' : 'PROPOSAL') | translate}}</span>
            {{(dynamicName == 'SUGGESTION' && x.statusNameAlt ? x.statusNameAlt : x.statusName) | lowercase}}
          </div>
          <div class="text">
            {{x.status_AdminMessage}}
          </div>
        </div>

        <div class="description">
          {{x.description}}
        </div>
                  
        <div *ngIf="x.locationLat">
          <h2>{{'LOCATION' | translate}}</h2>
          <div>{{locationString}}</div><br>
          <app-map [lat]="x.locationLat" [lng]="x.locationLng" [zoom]="16"></app-map>
        </div>

        <ion-grid *ngIf="!x.isSuggestion" class="ion-no-padding ion-padding-top">
          <ion-row>
            <ion-col size="6">
              <div *ngIf="statusHistory" class='line-container vertical'>
                <div class='progress-line'>
                  <div class='progress' [style.height.%]="statusPercent"></div>
                  
                  <div *ngFor="let s of statusHistory" class='status status_{{s.id}}' [class.completed]="s.completed" [class.current]="s.current">
                     <div class='dot'></div>
                     <div class='text'>
                       <div class='name' *ngIf="dynamicName == 'PROPOSAL'">{{s.statusName}}</div>
                       <div class='name' *ngIf="dynamicName == 'SUGGESTION'">{{s.statusNameAlt || s.statusName}}</div>
                       <time>
                        {{s.when | date:'shortDate'}}
                       </time>
                      </div>
                  </div>
                </div>
               
              </div>
            </ion-col>

            <ion-col size="6">
              <div class="votes-card" [style.backgroundColor]="x.categoryBackgroundGradientFrom">
                <table>
                  <tr>
                    <td><i class="icon-thumbs-up"></i></td>
                    <td>{{'IN_FAVOR' | translate}}:</td>
                    <td><span>{{x.votes_Positive}}</span></td>
                  </tr>
                  <tr>
                    <td><i class="icon-thumbs-down"></i></td>
                    <td>{{'AGAINST' | translate}}:</td>
                    <td><span>{{x.votes_Negative}}</span></td>
                  </tr>
                  <tr>
                    <td><ion-icon name="file-tray"></ion-icon></td>
                    <td>{{'TOTAL' | translate}}:</td>
                    <td><span>{{x.votes_Total}}</span></td>
                  </tr>
                  <tr>
                    <td><ion-icon name="heart"></ion-icon></td>
                    <td>{{'FAVORITES' | translate}}:</td>
                    <td><span>{{x.numFollowing}}</span></td>
                  </tr>
                </table>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button 
          *ngIf="isAuthenticated && !isPreview && !x.isSuggestion" 
          class="report-btn" 
          (click)="report()"
          expand="full" 
          fill="outline"
        >
          <span *ngIf="dynamicName == 'PROPOSAL'">{{'REPORT.Proposal' | translate}}</span>
          <span *ngIf="dynamicName == 'SUGGESTION'">{{'REPORT.Suggestion' | translate}}</span>
        </ion-button>

      </div>

      <div *ngIf="!isPreview && x.votes && x.votes.total > 3" class="votes-summary ion-padding-horizontal">
        <div class="user-images">
          <div *ngFor="let img of x.votes.randProfilePictures" class="image-circle">
            <img [src]="img | imageUrl | imageMode:'thumb'">
          </div>
        </div>
        <div class="text" [innerHTML]="'Consultations.HAS_VOTED_THIS' | translate:{ num: x.votes.total }">
        </div>
      </div>

      <div *ngIf="!isPreview && !x.isSuggestion">
        <h2 class="ion-padding">{{'COMMENTS' | translate}}</h2>
        <app-comments [id]="x.id"></app-comments>
      </div>

      <!--
      <div>
        <h2>{{'GIVE_SUPPORT' | translate}}</h2>
        <ion-grid class="share-follow-buttons">
          <ion-row>
            <ion-col>
              <ion-button expand="block" fill="outline">
                <i class="fas fa-share-alt" (click)="share()"></i> {{'SHARE' | translate}}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button expand="block" [fill]="x.followedByMe? 'solid' : 'outline'" (click)="follow()">
                <i class="fa-bookmark" [class.fas]="x.followedByMe" [class.far]="!x.followedByMe"></i> {{'FOLLOW' | translate}}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      -->

    </div>
  </ng-container>
</ion-content>

<ion-footer *ngIf="x && x.statusId == ProposalStatusVoting && !isPreview && !isVotingPage && x.creatorId != authUserId">
  <div class="bottom-buttons" [class.disabled]="x.votedByMe">
    
    <button class="dislike" (click)="vote(-1, $event)" [class.disabled]="x.myVote != -1">
      <div class="circle">
        <i class="icon-thumbs-down"></i>
      </div>
      <div class="wave"></div>
    </button>
   
    <button class="like" (click)="vote(1, $event)" [class.disabled]="x.myVote != 1">
      <div class="circle">
        <i class="icon-thumbs-up"></i>
      </div>
      <div class="wave"></div>
    </button>
    
  </div>
</ion-footer>

