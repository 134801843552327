import { NgModule } from '@angular/core';
import { ProposalDetailComponent } from './proposal-detail.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapComponentModule } from '../map/map.module';
import { CommentsComponentModule } from '../comments/comments.module';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';

@NgModule({
  declarations: [
    ProposalDetailComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MapComponentModule,
    CommentsComponentModule,
    TranslateModule,
    LazyLoadImageModule,
    ApplicationPipesModule
],
  exports: [
    ProposalDetailComponent
  ]
})
export class ProposalDetailComponentModule {}