<ion-content fullscreen>
  <div class="float-buttons left top">
    <a class='button' (click)="backStep()">
      <i class="fas fa-chevron-left"></i>
    </a>
  </div>

  <div *ngIf="!incident" class="pulse-spinner"></div>

  <app-incident-detail *ngIf="incident" [incident]="incident"></app-incident-detail>
</ion-content>
