import { LanguageService } from './services/language.service';
import { DetailPageModule as EventDetailPageModule } from './pages/calendar/detail/detail.module';
import { DetailPageModule as IncidentDetailPageModule } from './pages/incidents/detail/detail.module';
import { DetailPageModule as ConsultationDetailPageModule } from './pages/consultations/detail/detail.module';
import { ProposalDetailPageModule } from './pages/proposals/proposal-detail/proposal-detail.module';
import { APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';

import { IonicStorageModule, Storage } from '@ionic/storage';
import { AuthModule } from './auth/auth.module';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import ca from '@angular/common/locales/ca';
import fr from '@angular/common/locales/fr';

import { NotificationsPageModule } from './pages/notifications/notifications.module';
import { AuthService } from './auth/auth.service';
import { DetailPageModule } from './pages/notifications/detail/detail.module';
import { ApplicationPipesModule } from './pipes/application-pipes.module';
import { ChangeLocationPageModule } from './pages/user/change-location/change-location.module';
import { HtmlComponent } from './components/html/html.component';
import { HtmlComponentModule } from './components/html/html.module';
import { AnalyticsService } from './services/analytics.service';
import { ImagePopupComponentModule } from './components/image-popup/image-popup.module';
import { LanguageHttpInterceptor } from './interceptors/LanguageHttpInterceptor';
import { HttpLoaderFactory } from './languages/HttpLoaderFactory';
import { CommunityService } from './services/community/community.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import * as Sentry from "@sentry/angular-ivy";
import { InscriptionsComponent } from './pages/MODULES/inscriptions/inscriptions.component';

registerLocaleData(es);
registerLocaleData(ca);
registerLocaleData(fr);

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get(AuthService.TOKEN_KEY);
    },
    allowedDomains: [      
      'api.dev.wetown.app',
      'api.pre.wetown.app',
      'api.wetown.app',

      'api.dev.wecoo.app',
      'api.pre.wecoo.app',
      'api.wecoo.app',
      
      'localhost:8080'
    ],
    disallowedRoutes: []
  };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, CommunityService]
            }
        }),
        AuthModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            }
        }),
        NotificationsPageModule,
        ApplicationPipesModule,
        DetailPageModule,
        ChangeLocationPageModule,
        HtmlComponentModule,
        ImagePopupComponentModule,
        ProposalDetailPageModule,
        ConsultationDetailPageModule,
        IncidentDetailPageModule,
        EventDetailPageModule,
        BrowserAnimationsModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'es' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageHttpInterceptor,
            multi: true,
            deps: [LanguageService]
        },
        AnalyticsService,
        LocationAccuracy,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
