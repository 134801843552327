import { TranslateService } from '@ngx-translate/core';
import { ModalController, NavController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NewDTO } from 'src/app/models/NewDTO';
import { ReactionType } from 'src/constants';
import { Share } from '@capacitor/share';
import { CommunityService } from 'src/app/services/community/community.service';
import { first } from 'rxjs/operators';
import { Helpers } from 'src/app/helpers';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.page.html',
  styleUrls: ['./news-detail.page.scss'],
})
export class NewsDetailPage implements OnInit {

  @Input()
  id: number;
  new: NewDTO;

  public webScrapingElementDomain: string;

  isLoading = true;

  reactionType = ReactionType.NEW;

  translations = {}

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    public nav: NavController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private communityService: CommunityService,    
  ) {
  }

  async ngOnInit() {
    Fancybox.bind("[data-fancybox]", {
      on: {
        "close": (event) => {
          this.nav.back();
        }
      }
    })

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = parseInt(id);
    }
    
    let townIdStr = this.route.snapshot.paramMap.get('town');
    let townId: number;

    if(townIdStr){
      townId = parseInt(townIdStr);
    } else {
      let c = await this.communityService.Get().pipe(first()).toPromise();
      townId = c.id;
    }    
    
    this.api.getNew(townId, this.id).then((res) => {
      this.new = res;
      this.getTextsToTranslate();
      this.isLoading = false;      

      this.webScrapingElementDomain = Helpers.GetOnlyTheHostFromUrl(this.new.webScrapingElementUrl);
    });

    Fancybox.bind("[data-fancybox]", {
      on: {
        "close": (event) => {          
          this.nav.back();          
        }
      }
    })
  }

  getTextsToTranslate() {
    this.translations = { 'title': this.new.title, 'body': this.new.body }
  }
  
  async back() {
    const top = await this.modalCtrl.getTop();

    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }

  async share() {
    const t = await this.translate.get(['SHARE']).toPromise();
    const shareLink = await this.communityService.MakeShareLink(`/news/${this.new.id}`);

    const shareRet = await Share.share({
      title: '',
      text: '',
      url: shareLink,
      dialogTitle: t.SHARE
    });

    //console.log(shareRet);
    
    //this.analytics.trackEvent('proposals', 'share');
  }

  getTranslations(event) {   
    this.new.title = event['title'];
    this.new.body = event['body'];
  }
}
