export class ElementTile {
    public title: string;
    public image: string;
    public description: string;
    public dateFrom: string;
    public dateTo: string;

    constructor(){
    }

}