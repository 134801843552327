import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { deepmerge } from "deepmerge-ts";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import { CommunityType } from "src/constants";
import { API_URL } from "src/environments/environment";
import { CommunityService } from "../services/community/community.service";

export interface TranslationResource {
    prefix: string;
    suffix: string;
  }
  
  export class MultiTranslateHttpLoader implements TranslateLoader {

    constructor(
      private http: HttpClient,
      private defaultResources: TranslationResource[],
      private communityService: CommunityService
    ) {

    }
  
    public getTranslation(locale: string): Observable<{[key: string]: string}> {

      return new Observable((subs) => {

        this.communityService.Get().subscribe(async (c) => {

          let communityType: CommunityType;
          let communityCode: string;
          
          if(c){
            communityCode = c.code;
            communityType = c.communityType;
          } else {
            communityType = await this.communityService.GetCommunityType().pipe(first()).toPromise();
            communityCode = await this.communityService.GetAppIdDefaultCommunityCode();
          }

          let resources = [...this.defaultResources];
          
          // load overriding strings if app is in override mode.
          if (communityType != CommunityType.Town) {
            resources.push({
              prefix: "./assets/i18n/weco/",
              suffix: ".json"
            });
          }

          if(communityCode){
            resources.push({
              prefix: "./assets/i18n/weco/" + communityCode + "/",
              suffix: ".json"
            });
          }

          const requests = resources.map(resource => {
            const path = resource.prefix + locale + resource.suffix;
            return this.http.get(path)
            .pipe(catchError(() => {
              // return empty object if file is not available.
              return of({});
            }));
    
          });

          if(communityCode){
            const getServerI18n = this.http.get(`${API_URL}/v2/Communities/${communityCode}/i18n?platform=app`)
            .pipe(map(x => x ?? {}), catchError((err) => {
              if(err.status == 200){
                console.error(err?.message, err?.error?.error);
              }
              // return empty object if file is not available.
              return of({});
            }));
          
            requests.push(getServerI18n)
          }

          forkJoin(requests)
          .pipe(map(response =>  {
            const mergedStrings = deepmerge({}, ...response );
            return mergedStrings;
          }))
          .subscribe((response) => {
            subs.next(response);
          });

        });

      });

    }
  }