import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApiService } from 'src/app/services/api.service';
import { ReactionType } from 'src/constants';

@Component({
  selector: 'app-feedback-faces',
  templateUrl: './feedback-faces.component.html',
  styleUrls: ['./feedback-faces.component.scss'],
})
export class FeedbackFacesComponent implements OnInit, OnDestroy {

  @Input() type: ReactionType;
  @Input() id: number;

  feedbackActive: number;

  isAuthenticated: boolean;
  subscription: Subscription;

  constructor(
    private api: ApiService,
    private analytics: AnalyticsService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.subscription = this.auth.isAuthenticatedObs().subscribe((val) => {
      if (val) {
        let user = this.auth.getUser();
        if(user.roles.includes('User')){
          this.isAuthenticated = true;
          this.api.Reactions_Get(this.type, this.id)
          .then((res) => {
            if (res) {
              this.feedbackActive = res.reaction;
            } else {
              this.feedbackActive = null;
            }
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  feedbackClick(num: number) {
    this.feedbackActive = num;

    this.api.Reactions_Set(this.type, this.id, num);

    this.analytics.trackEvent('feedback-faces', 'click', null, num);
  }

}
