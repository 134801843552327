import { LanguageService } from '../services/language.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
 
@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
 
    constructor(public language: LanguageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let lang = this.language.selected;
        
        if (lang) {
            request = request.clone({ headers: request.headers.set('Accept-Language', lang) });
        }

        return next.handle(request);
    }
 
}