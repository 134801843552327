<ion-app [ngClass]="appTypeClass + ' ' + communityClass" [style]="cssVariables">
  <ion-menu menuId="main" side="start" type="push" contentId="main">
    <ion-header class="corp">
      <ion-toolbar class="user" *ngIf="user">
        <div class="wrapper" (click)="GoToUserPage()">
          <div class="avatar">
            <img *ngIf="user.image" [src]="user.image | imageUrl | imageMode:'thumb'">
          </div>
          <div class="info">
            <div class="name">{{user.name}} {{user.surname}}</div>
            <div class="email">{{user.mail}}</div>
          </div>
        </div>
        <ion-button expand="block" fill="clear" slot="end" (click)="LogOut()">
          <ion-icon slot="icon-only" name="log-out"></ion-icon>
        </ion-button>
      </ion-toolbar>
      <ion-toolbar class="user" *ngIf="!user">
        <div class="wrapper" (click)="LogIn()">
          <div class="avatar">
            <img [src]="'/users/default/avatar.png' | imageUrl">
          </div>
          <div class="info">
            <div class="name">{{'LOG-IN' | translate}}</div>
            <div class="email">{{'SIGN_UP' | translate}}</div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>

        <ion-menu-toggle auto-hide="false">
          <ion-item [routerLink]="['/tabs']">
            <ion-icon color="primary" slot="start" name="home"></ion-icon>
            <ion-label>
              {{'HOME' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false">
          <ion-item [routerLink]="['/resources/news']">
            <ion-icon color="primary" slot="start" name="newspaper"></ion-icon>
            <ion-label>
              {{'NEWS' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false">
          <ion-item [routerLink]="['/resources']">
            <ion-icon color="primary" slot="start" name="grid"></ion-icon>
            <ion-label>
              {{'RESOURCES' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *ngIf="modules['PROPOSALS'] && proposalsModeVote && isUser" auto-hide="false">
          <ion-item [routerLink]="['/tabs/following-proposals']">
            <ion-icon color="primary" slot="start" name="heart"></ion-icon>
            <ion-label *ngIf="dynamicName == 'PROPOSAL'">
              {{'Proposals.FOLLOWED_PROPOSALS' | translate}}
            </ion-label>
            <ion-label *ngIf="dynamicName == 'SUGGESTION'">
              {{'Suggestion.FOLLOWED' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <!--<ion-menu-toggle auto-hide="false" *ngIf="auth.isAuthenticated()">
          <ion-item [routerLink]="['/tabs/user']">
            <ion-icon color="primary" slot="start" name="person"></ion-icon>
            <ion-label>
              Usuari
            </ion-label>
          </ion-item>
        </ion-menu-toggle>-->

        <ion-menu-toggle auto-hide="true" *ngIf="user">
          <ion-item [routerLink]="['/tabs/user']">
            <ion-icon color="primary" slot="start" name="person"></ion-icon>
            <ion-label>
              {{'MY_PROFILE' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="true" *ngIf="user && modules['TICKETING'] && isUser">
          <ion-item [routerLink]="['/tickets/my-tickets']">
            <ion-icon color="primary" slot="start" name="ticket"></ion-icon>
            <ion-label>
              {{'Modules.Ticketing.MY_TICKETS' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="true" *ngIf="user && isAdmin">
          <ion-item [routerLink]="['/admin-options']">
            <ion-icon color="primary" slot="start" name="construct"></ion-icon>
            <ion-label>
              {{'AdminOptions.TITLE' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="true">
          <ion-item [routerLink]="['/info-community']">
            <ion-icon color="primary" slot="start" name="information-circle"></ion-icon>
            <ion-label>
              {{'ABOUT_AJ' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false">
          <ion-item (click)="openTypeForm()">
            <ion-icon color="primary" slot="start" name="happy-outline"></ion-icon>
            <ion-label>
              {{'HELP_US_SURVEY' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false" *ngIf="languageService.available_languages.length > 1">
          <ion-item (click)="presentLanguageActionSheet()">
            <ion-icon color="primary" slot="start" name="globe-outline"></ion-icon>
            <ion-label>
              {{'CHANGE_LANG' | translate}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        
        
        <ion-menu-toggle auto-hide="false" *ngIf="isEnvDev || isDeveloper">
          <ion-item [routerLink]="['/developer']">
            <ion-icon color="primary" slot="start" name="bug-outline"></ion-icon>
            <ion-label>
              Developer Options
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        
      </ion-list>
    </ion-content>
    <ion-footer>

      <div class="logo town" *ngIf="town">
        <img *ngIf="theme.customLogo" [src]="town.image | imageUrl" />
        <div *ngIf="theme.appName" class="app-name" [innerHTML]="theme.appName"></div>
        
        <div class="by-wetown" *ngIf="town.communityType == 0">
          <span *ngIf="theme.appName">by</span> 
          <img src="assets/logos/wetown/horizontal-gray.svg" (click)="openUrl('https://wetown.app')"/>
        </div>

        <div class="by-weco" *ngIf="town.communityType != 0">
          <span *ngIf="theme.appName">by</span> 
          <img src="assets/logos/wecoo/horizontal-gray.svg" (click)="openUrl('https://wecoo.app')"/>
        </div>

      </div>

      <span (click)="easterEgg($event)">{{versionLabel}}</span>
    </ion-footer>
  </ion-menu>

  <ion-menu menuId="notifications" side="end" type="push" contentId="main" (ionWillOpen)="menuOpened()" disabled="true">
    <app-notifications [refresh]="notificationsRefresh"></app-notifications>
  </ion-menu>

  <ion-router-outlet id="main"></ion-router-outlet>

  <app-image-popup *ngIf="popup" [src]="popup.imagePopUp || popup.image | imageUrl:'notifications'" [url]="'/announcements/' + popup.id"></app-image-popup>

  <div id="party-js-container" style="position: fixed; left: 0px; top: 0px; height: 100vh; width: 100vw; pointer-events: none; user-select: none; z-index: 99999;"></div>
</ion-app>
