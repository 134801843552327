import { StorageService } from './../../services/storage.service';
import { Component, OnInit, Input, EventEmitter, ViewChild, SimpleChanges, ElementRef, AfterViewInit, OnChanges, HostBinding } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ProposalModel } from 'src/app/models/ProposalModel';
import { IonContent } from '@ionic/angular';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProposalStatus } from 'src/constants';
import { MapboxService } from 'src/app/services/mapbox.service';
import { latLng } from 'leaflet';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Share } from '@capacitor/share';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { CommunityService } from 'src/app/services/community/community.service';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss'],
})
export class ProposalDetailComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('main') mainElement: ElementRef;
  @ViewChild('progressPill') progressPill: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;

  @Input('proposal') x: ProposalModel;
  @Input() isPreview: boolean;
  @Input() isVotingPage: boolean;

  @Input() scrollTop: EventEmitter<boolean>;

  @HostBinding('style')
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--progress-color: ${this.x.categoryBackgroundGradientFrom}`);
  }

  statusHistory: Array<any>;
  statusPercent: number;

  progressBarPercent: number;

  tooltipEnabled = true;
  tooltipOpacity = 0;
  tooltipLeft = 4;
  angleLeft = 10;
  tooltipAnimation: string;

  imagesOpts = {
    //initialSlide: 1,
    //speed: 400,
    //freeMode: true,
    //slidesOffsetBefore: 30,
    //slidesOffsetAfter: 30,
    //spaceBetween: 0,
    //slidesPerView: 1
  };

  likeButtonAnimation: string;
  shareButtonAnimation: string;

  isAuthenticated = false;
  authUserId: number;

  locationString = '';

  expiresTime: string;

  public ProposalStatusVoting = ProposalStatus.EnProcesoVotacion;

  public dynamicName = 'PROPOSAL';

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private mapbox: MapboxService,
    public sanitizer: DomSanitizer,
    private analytics: AnalyticsService,
    private storageSvc: StorageService,
    private communityService: CommunityService
  ) {

   }

  ngOnInit() {
    this.auth.isAuthenticatedObs().subscribe((res) => {
      this.isAuthenticated = res;

      if (res) {
        this.authUserId = this.auth.getUser().id;
      } else {
        this.authUserId = null;
      }
    });

    if (this.x.locationLat) {
      this.mapbox.reverseGeocode_GetStreetAndNumber(latLng(this.x.locationLat, this.x.locationLng))
      .then(res => {
        this.locationString = res;
      });
    }

    this.storageSvc.GetTownParameters().subscribe((res) => {
      //this.proposalsModeVote = res["PROPOSALS.MODE"] != 'SUGGESTION';
      this.dynamicName = res['PROPOSALS.DYNAMIC_NAME'] ?? 'PROPOSAL';
    });
  }

  ngAfterViewInit() {

    if (this.scrollTop) {
      this.scrollTop.subscribe(x => {
        this.content.scrollToTop(300);
      });
    }

    this.api.getProposalsStatusHistory(this.x.id).then((res) => {
      this.processStatusHistory(res);
    });

    this.api.getProposal(this.x.id).then((res) => {
      
      this.x.followedByMe = res.followedByMe;

      if (res.statusId === ProposalStatus.EnProcesoVotacion
          && res.expiresAt
          && moment(res.expiresAt).isAfter()
        ) {
        this.expiresTime = moment().to(res.expiresAt);
      }
      
      setTimeout(() => {
        this.syncProgress();
      }, 100);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  syncProgress() {

    if(!this.progressPill) return;

    const width = (this.mainElement.nativeElement as HTMLElement).offsetWidth;
    const progressPillWidth = (this.progressPill.nativeElement as HTMLElement).offsetWidth;
    const pillMargin = (progressPillWidth / 2 + 4);

    const progressBarValue = this.x.votes_Balance / this.x.votes_Objective;
    
    let progressBarPx = (progressBarValue * width);
    
    if (progressBarPx < pillMargin) {
      progressBarPx = pillMargin;
    } else if (progressBarPx > (width - pillMargin)) {
      progressBarPx = (width - pillMargin);
    }

    this.progressBarPercent =  progressBarPx / width;

    const tooltipWidth = (this.tooltip.nativeElement as HTMLElement).offsetWidth;

    if (progressBarPx > width - (tooltipWidth / 2 + 4)) {
      this.tooltipLeft = width - tooltipWidth - 4;
      //this.showTooltip = true;
      //this.showTooltip = false;
    } else if (progressBarPx > ((tooltipWidth / 2) + 4)) {
      this.tooltipLeft = progressBarPx - (tooltipWidth / 2) + 4;
      //this.tooltipLeft = 4;
      //this.showTooltip = false;
    } else {
      this.tooltipLeft = 4;
      //this.showTooltip = true;
    }

    this.angleLeft = progressBarPx - this.tooltipLeft - 6;
    
    if (this.x.statusId === ProposalStatus.EnProcesoVotacion && this.x.votes_Balance !== 0) {
      
      setTimeout(() => {
        this.showTooltip();
      }, 400);
    } else {
      this.tooltipEnabled = false;
    }
  }

  showTooltip() {
    if (this.x.statusId !== ProposalStatus.EnProcesoVotacion) return;

    this.tooltipEnabled = true;
    this.tooltipOpacity = 1;
    this.tooltipAnimation = 'in';


    setTimeout(() => {
      this.tooltipAnimation = 'out';
      setTimeout(() => {
        this.tooltipAnimation = null;
        this.tooltipEnabled = false;
      }, 1000);
    }, 4000);
  }

  async ShowInfo() {
    this.storageSvc.GetTownParameters().subscribe((params) => {
      let votes = params['PROPOSAL_VOTES_OBJECTIVE'];
      let expireDays = params['EXPIRE_PROPOSAL_TIME_DAYS'];

      let langkey = 'Proposals.MoreInfo.VOTES';
      if(params['PROPOSALS.DYNAMIC_NAME'] == 'SUGGESTION'){
        langkey = 'Suggestion.MoreInfo.VOTES';
      }

      this.translate.get(['Proposals.MoreInfo.VOTES_TITLE', langkey, 'CONTINUE'], { votes, expireDays })
      .subscribe(t => {
        Swal.fire({
          icon: 'question',
          title: t['Proposals.MoreInfo.VOTES_TITLE'],
          html: t[langkey],
          confirmButtonText: t.CONTINUE,
          customClass: {
            popup: 'help text-left',
            content: 'font-size-small',
          },
        });
      });
    });
  }

  follow() {
    if (!this.isAuthenticated) {
      this.translate.get(['Oops', 'Proposals.YOU_NEED_TO_BE_LOGGED', 'LOGIN', 'CANCEL'])
      .subscribe(t => {
        Swal.fire({
          title: t.Oops,
          text: t['Proposals.YOU_NEED_TO_BE_LOGGED'],
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: t.LOGIN,
          cancelButtonText: t.CANCEL,
          reverseButtons: true
        })
        .then((result) => {
          if (result.value) {
           this.router.navigate(['/login']);
          }
        });
      });
     
      return;
    }

    if (!this.x.followedByMe) {
      this.likeButtonAnimation = 'animate-like';
      this.x.followedByMe = true;
      this.api.proposal_Follow(this.x.id).then();
      this.analytics.trackEvent('proposals', 'follow');
    } else {
      this.likeButtonAnimation = 'animate-unlike';
      this.x.followedByMe = false;
      this.api.proposal_Unfollow(this.x.id).then();
      this.analytics.trackEvent('proposals', 'unfollow');
    }
    
  }

  report() {
    let textkey = this.dynamicName == 'SUGGESTION' ? 'Suggestion.REPORT_CONFIRMATION' : 'Proposals.REPORT_CONFIRMATION';

    this.translate.get(['SURE?', textkey, 'LOGIN', 'CANCEL'])
    .subscribe(t => {
      Swal.fire({
        title: t['SURE?'],
        text: t[textkey],
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Denunciar',
        confirmButtonColor: '#dd3333',
        cancelButtonText: t.CANCEL,
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          this.api.proposal_report(this.x.id).then();
          this.analytics.trackEvent('proposals', 'report');
        }
      });
    });
  }

  async share() {
    this.shareButtonAnimation = 'animate';

    setTimeout(() => {
      this.shareButtonAnimation = '';
    }, 1000);

    const t = await this.translate.get(['SHARE']).toPromise();
    const shareLink = await this.communityService.MakeShareLink('/proposals/' + this.x.id);

    const shareRet = await Share.share({
      title: '',
      text: '',
      url: shareLink,
      dialogTitle: t.SHARE
    });

    console.log(shareRet);

    this.analytics.trackEvent('proposals', 'share');
  }

  processStatusHistory(history: Array<any>) {
    // Add to beginning
    history.unshift({
      when: moment(this.x.createdAt),
      statusName: this.dynamicName == 'SUGGESTION' ? this.translate.instant(`Suggestion.Status.Published`) :this.translate.instant(`Proposals.Status.Published`),
      completed: true,
    });
    
    // Check the current status (last completed status)
    history.some((el, i) => {
      if (!el.completed && i > 0) {
        history[i - 1].current = true;
        return true;
      }
    });

    this.statusHistory = history;
    this.statusPercent = ((this.statusHistory.filter(x => x.completed).length - 1) / (this.statusHistory.length - 1)) * 100;
  }

  async vote(type: number, e?: any) {

    if (!this.isAuthenticated && type !== 0) {
      const t = await this.translate.get(['Oops', 'Vote.YOU_NEED_TO_BE_LOGGED', 'LOGIN', 'CANCEL']).toPromise();

      Swal.fire({
        title: t.Oops,
        text: t['Vote.YOU_NEED_TO_BE_LOGGED'],
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t.LOGIN,
        cancelButtonText: t.CANCEL,
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
         this.router.navigate(['/login']);
        }
      });
      return;
    }

    Haptics.impact({
      style: ImpactStyle.Light
    });

    if (e) {
      e.target.closest('button').classList.add('animate');
      setTimeout( () => {
        e.target.closest('button').classList.remove('animate');
      }, 1500);
    }

    if (this.isAuthenticated) {
      this.api.voteProposal(this.x.id, type).then().catch();
    }

    //this.x.voted = true;
    setTimeout( () => {
      this.x.votedByMe = true;
    }, 1500);
  }

  
}
