import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss'],
})
export class HtmlComponent implements OnInit {

  @Input() html;

  constructor(public popoverCtrl: PopoverController) { }

  ngOnInit() {}

  dismiss() {
    this.popoverCtrl.dismiss(null, 'popover');
  }

}
