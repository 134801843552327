import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateButtonComponent } from './translate-button.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule
    ],
    declarations: [
        TranslateButtonComponent
    ],
    exports: [TranslateButtonComponent]
})

export class TranslateButtonModule { }
