import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
    ) {
  }

  transform(value: any, pattern: string = 'mediumDate'): any {
    const lang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(lang);
    let str = datePipe.transform(value, pattern);
    if (pattern == 'MMM'){
      if (lang == 'ca'){
        str = str.replace('de ', '').replace('.', '');
      } else if(lang == 'es'){
        str = str.replace('.', '');
      }
    }
    return str;
  }

}