import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as defineCustomElementsStripe } from '@stripe-elements/stripe-elements/loader';
import * as Sentry from "@sentry/angular-ivy";

if (environment.tag != 'dev' && environment.tag != 'local'){
  Sentry.init({
    dsn: "https://b01799ee0c2c4a23bc800f7ca6f29151@o4504844807831552.ingest.us.sentry.io/4505165640368128",
    environment: environment.tag,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.?[\w]*\.(wetown\.app|wecoo\.app)*/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => defineCustomElementsStripe(window))
  .catch(err => console.log(err));

  // Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
