import { FileOpener } from '@capacitor-community/file-opener';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private http: HttpClient
    ) { }

  public async checkDownloadAndOpenFile(url?: string, directory = Directory.Cache): Promise<boolean>{

     if (Capacitor.isNativePlatform()){
      const name = this.getFileName(url);
      
      const fileExists = await this.fileExists(name, directory);
      if (fileExists){
        const uriRes = await Filesystem.getUri({
          path: name,
          directory
        });
        
        return this.openFile(uriRes.uri);
      }else{
        const uri = await this.downloadFile(url, directory);
        return this.openFile(uri);
      }
    } else{
      window.open(url);
      return Promise.resolve(false);
    }
  }

  private downloadFile(url?: string, directory = Directory.Cache): Promise<string> {
    return new Promise<string>((resolve, _reject) => {
      this.http.get(url, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe(async (event) => {
        if (event.type === HttpEventType.DownloadProgress){
          //this.downloadProgress = Math.round((100*event.loaded) / event.total);
        } else if (event.type === HttpEventType.Response){
          //this.downloadProgress = 0;
  
          const name = this.getFileName(url);
          const uri = this.writeBlob(name, event.body, directory);
  
          console.log('downloadedFile:', uri);

          resolve(uri);
        }
      });
    });
  }

  private writeBlob(path, blob: Blob, directory: Directory): Promise<string>{
    return write_blob({
      path,
      blob,
      directory,
      recursive: true,
      on_fallback(error) {
          console.error(error);
      }
    });
  }

  private getMimeType(name: string){
    if (name.indexOf('.pdf') >= 0){
      return 'application/pdf';
    } else if (name.indexOf('.png') >= 0){
      return 'image/png';
    } else if (name.indexOf('.xls') >= 0){
      return 'application/excel';
    } else if (name.indexOf('.jpg') >= 0){
      return 'image/jpeg';
    } else if (name.indexOf('.mp4') >= 0){
      return 'video/mp4';
    } else if (name.indexOf('.rar') >= 0){
      return 'application/x-rar-compressed';
    } else if (name.indexOf('.zip') >= 0){
      return 'application/zip';
    }
  }

  private async openFile(f: string){
    return new Promise<boolean>((resolve, reject) => {
      //const name = this. path: f(f);
      //const mimeType = this.getMimeType(name);

      FileOpener.open({
       filePath: f
      })
      .then((res) => {
        resolve(true);
        console.log(res);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }
  
  public async fileExists(fileName: string, directory = Directory.Cache) {
    if (!Capacitor.isNativePlatform()){
      return false;
    }
    
    try {
      const ret = await Filesystem.readdir({
        path: '/',
        directory
      });

      if (ret.files.map(x => x.name).includes(fileName)) {
        return true;
      }
    } 
    catch (e) {
      console.log('Unable to read dir: ' + e);
    }

    return false;
  }

  private getFileName(path: string): string{
    return path.substring(path.lastIndexOf('/') + 1);
  }
}
