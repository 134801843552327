import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  id: string;

  constructor(
  ) { }

  startTrackerWithId(id: string) {
    if (environment.tag !== 'prod') return;

    this.id = id;
    gtag('config', id);
  }

  trackView(pageUrl: string, screenName: string) {
    if (this.id) {
      gtag('config', this.id, 
      {
        page_title: screenName,
        page_path: pageUrl
      });
    }
  }

  trackEvent(category, action, label?, value?) {
    if (this.id) {
      gtag('event', action, { 
        eventCategory: category, 
        eventLabel: label, 
        eventAction: action, 
        eventValue: value
      });
    }
  }
}
