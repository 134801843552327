import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { DateTime } from "luxon";
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { CommonModule } from "@angular/common";
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { isValid } from 'iban-ts';
import { CustomFieldDTO } from 'src/app/models/CustomFieldDTO';
import { CustomFieldsConstants } from 'src/constants';

@Component({
  selector: 'app-custom-field-input',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomFieldFormComponent),
      multi: true
    }
  ],
  templateUrl: './custom-field-input.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    TranslateModule,
    InputNumberModule,
    InputMaskModule
  ],
  styleUrls: ['./custom-field-input.component.scss']
})
export class CustomFieldFormComponent implements OnInit, ControlValueAccessor{

  public internalValue: any;

  public show = false;
  public oldValue: string;

  public InputTypes = CustomFieldsConstants.InputTypes;
  public DropdownTypes = CustomFieldsConstants.DropdownTypes;

  @Input()
  public customField: CustomFieldDTO;

  @Output() changeThePrice = new EventEmitter<any>();
  onChange: (_: string) => void = (_: string) => { };

  constructor() {

  }

  ngOnInit(): void {

  }

  updateChanges(value: any) {
    this.customField.value = this.modelToString(value);
    this.onChange(this.customField.value);
  }

  private modelToString(value: any){
    if (!value) { return null; }

    switch (this.customField.type) {
      case CustomFieldsConstants.InputTypes.DATE:
      case CustomFieldsConstants.InputTypes.DATETIME:
        return DateTime.fromJSDate(value).toISO();
      case CustomFieldsConstants.InputTypes.CHECKBOX:
        return value.toString();
      case CustomFieldsConstants.InputTypes.NUMERIC:
        return value.toString();
      default:
        return value;
    }
  }

  private modelFromString(value: string): any{
    if (!value) { return null; }

    switch (this.customField.type) {
      case CustomFieldsConstants.InputTypes.DATE:
      case CustomFieldsConstants.InputTypes.DATETIME:
        return DateTime.fromISO(value).toJSDate();
        case CustomFieldsConstants.InputTypes.CHECKBOX:
        return value == 'true';
      default:
        return value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  writeValue(val: string): void {
    if (this.customField) {
      this.customField.value = val;

      if (this.customField.dropdownOptions != '' && this.customField.dropdownOptions && this.customField.dropdownType === "CHIPS"){

        this.customField.dropdownOptionsArrayShow = this.customField.dropdownOptions.split('|')
      }
    }

    this.internalValue = this.modelFromString(val);
  }

  validate({ value }: FormControl) {
    // Required
    if (this.customField.required && (value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0))){
      return {
        required: true
      }
    }

    if (value){
      // IBAN
      if (this.customField.type ==  CustomFieldsConstants.InputTypes.IBAN && !isValid(value)){
        return {
          format: true
        }
      }
      // DNI
      if (this.customField.type == CustomFieldsConstants.InputTypes.DNI && !this.nifValido(value)){
        return {
          format: true
        }
      }
    }
    
    return false;
  }

  public showThePrice(event: any){

    var price = this.customField.dropdownOptionsTable.find(x=> x.name == event)?.price ?? 0;
    var eliminar = false;
    if(price == 0){
       price = this.customField.dropdownOptionsTable.find(x=> x.name == this.oldValue)?.price ?? 0;
        eliminar = true;
    }
    this.oldValue = event;
    this.changeThePrice.emit({price: price, delete: eliminar});
  }

  public nifValido(nif: string): boolean {
    nif = nif.toUpperCase();

    const nifRegEx = /^[0-9]{8}[A-Z]$/i;
    const nieRegEx = /^[XYZ][0-9]{7}[A-Z]$/i;
    const letras = "TRWAGMYFPDXBNJZSQVHLCKE";

    if (nifRegEx.test(nif)) {
        return letras[parseInt(nif.substring(0, 8)) % 23] === nif[8];
    } else if (nieRegEx.test(nif)) {
        const firstChar = nif[0];
        if (firstChar === "X") nif = "0" + nif.substring(1);
        else if (firstChar === "Y") nif = "1" + nif.substring(1);
        else if (firstChar === "Z") nif = "2" + nif.substring(1);

        return letras[parseInt(nif.substring(0, 8)) % 23] === nif[8];
    } else {
        return false;
    }
}


}
