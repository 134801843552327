import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserModel } from 'src/app/models/UserModel';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { CommentDTO } from 'src/app/models/CommentDTO';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ReactionType } from 'src/constants';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {

  @Input('element') element: string;
  @Input('id') id: number;

  public isEnvDev = false;

  comments: Array<CommentDTO>;

  isLogged = false;
  userModel: UserModel;

  newMessage: string;
  

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private translate: TranslateService,
    private analytics: AnalyticsService
  ) { 

    if (environment.tag === 'dev') { 
      this.isEnvDev = true; 
    }
  }

  ngOnInit() {
    this.refreshComments();

    this.auth.isAuthenticatedObs().subscribe((res) => {
      this.isLogged = res;

      if (res) {
        this.api.getUser().then((res) => { 
          this.userModel = res;
        });
      } else {
        this.userModel = null;
      }
    });
    
  }

  refreshComments() {
    this.api.getProposalsComments(this.id).then((res) => {
      this.comments = res.map((x) => {
        const m = moment(x.createdAt);

        if (m.isAfter()) {
          x.createdAtString = '';

          this.translate.get('RIGHT_NOW').subscribe(res => {
            x.createdAtString = res;
          });

        } else {
          x.createdAtString = moment(x.createdAt).fromNow(true);
        }
        
        /*if (this.auth.isAuthenticated()) {
          this.api.Reactions_Get(ReactionType.COMMENT, x.id).then((reaction) => {
            x.likedByMe = (reaction && reaction.like);
          });
        }*/
        return x;
      });
    });
  }

  sendComment() {
    if (!this.newMessage) return;
    
    this.api.postProposalComment(this.id, this.newMessage).then(() => {
      this.comments.push({
        id: 0,
        message: this.newMessage,
        userImage: this.userModel.image,
        name: this.userModel.name
      });
      this.newMessage = '';
      this.refreshComments();
    });

    this.analytics.trackEvent('comments', 'send');
  }

  async removeComment(id: number) {
    const t = await this.translate.get(['SURE?', 'Coments.REMOVE_COMENT', 'DELETE', 'CANCEL']).toPromise();

    Swal.fire({
      title: t['SURE?'],
      text: t['Coments.REMOVE_COMENT'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t.DELETE,
      cancelButtonText: t.CANCEL,
      reverseButtons: true,
      confirmButtonColor: '#dd3333'
    })
    .then((result) => {
      if (result.value) {
        this.api.deleteProposalsComment(this.id, id).then((res) => {
          this.refreshComments();
        });
        this.analytics.trackEvent('comments', 'remove');
      }
    });
  }

  async likeUnlikeComment(c: CommentDTO) {
    c.likedByMe = !c.likedByMe;
    
    c.likes += c.likedByMe? 1 : -1;

    this.api.Reactions_Like_Set(ReactionType.COMMENT, c.id, c.likedByMe);

    this.analytics.trackEvent('comments', 'like', null, c.likedByMe);
  }

  async showActions() {
    const promptRet = await ActionSheet.showActions({
      title: 'Options',
      message: 'Select an option to perform',
      options: [
        {
          title: 'Upload'
        },
        {
          title: 'Share'
        },
        {
          title: 'Remove',
          style: ActionSheetButtonStyle.Destructive
        }
      ]
    });

    console.log('You selected', promptRet);

  }
}
