import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject, Subscription } from 'rxjs';
import { RelativeTimeClock } from './relative-time.clock';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'relativeTime',
  pure: false
})
export class RelativeTimePipe implements PipeTransform, OnDestroy {

  private clockSubscription: Subscription;

  private date: DateTime;
  private calendarFormat: boolean;

  private value: string;

  private stateChanges = new Subject<void>();

  constructor(
    cd: ChangeDetectorRef,
    private clock: RelativeTimeClock,
    private translate: TranslateService
  ) {
    
    this.stateChanges.subscribe(() => {
      if (this.calendarFormat){
        this.value = this.date.toRelativeCalendar();
      } else {
        if (Math.abs(this.date.diffNow().as('seconds')) < 60){
          this.value = this.translate.instant('just_now');
        } else {
          this.value = this.date.toRelative();
        }
        
      }
      cd.markForCheck();
    });
  }

  transform(date: any, allDayEvent = false) {    
    this.date = (typeof date === 'string') ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
    this.calendarFormat = allDayEvent;

    if (this.date) {
      if (this.clockSubscription) {
        this.clockSubscription.unsubscribe();
        this.clockSubscription = undefined;
      }

      this.clockSubscription = this.clock.tick(this.date)
        .subscribe(() => this.stateChanges.next());

      this.stateChanges.next();
    } else {
      throw new SyntaxError(`Wrong parameter in RelativeTimePipe. Expected a valid date, received: ${date}`);
    }

    return this.value;
  }

  ngOnDestroy() {
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = undefined;
    }
    this.stateChanges.complete();
  }

}