import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { STORAGE_PRESENTATION } from 'src/constants';

@Injectable({
  providedIn: 'root'
})
export class PresentationGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: Storage
  ) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Promise<boolean> {

    const presentationDone = await this.storage.get(STORAGE_PRESENTATION);
    
    if(presentationDone){
      return true;
    }else{
      this.router.navigate(['/presentation'], { replaceUrl: true });
      return false;
    }
  }
  
}
